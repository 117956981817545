// Making changes to this file
// 
// 1. Enable the overwrite variable related to this component in _variables.scss under "component overwrites"
// 2. Changes here will only affect the component in all pages its been added

@mixin search {
    .search {
        margin: 0;
		flex: 1;

        input[type="search"] {
            font-size: 1rem;
            padding-left: 48px;
            padding-right: 18px;
            border-color: #c4c4c4;
        }

        button {
            left: 0;
            right: auto;
        }

        svg {
            color: #cdcfd1;
            height: 21px;
            width: 20px;
        }

        // responsive
        @media (min-width: 1024px) {
            width: 250px;
        }

        @media (min-width: 1366px) {
            width: 350px;
        }
    }
}