// helpers
.m0     { margin: 0 !important;            }
.mt0    { margin-top: 0 !important;        }
.mb0    { margin-bottom: 0 !important;     }
.ml0    { margin-left: 0 !important;       }
.mr0    { margin-right: 0 !important;      }
.p0     { padding: 0 !important;           }
.pt0    { padding-top: 0 !important;       }
.pb0    { padding-bottom: 0 !important;    }
.pl0    { padding-left: 0 !important;      }
.pr0    { padding-right: 0 !important;     }
.tac    { text-align: center !important;   }
.tal    { text-align: left !important;     }
.tar    { text-align: right !important;    }
.bdn    { border: none !important;         }
.db     { display: block !important;       }
.dn     { display: none !important;        }
.pen    { pointer-events: none !important; }
.pull-left { float: left !important; }
.pull-right { float: right !important; }
.clear-ul { list-style: none; padding-left: 0; margin: 0; }
.w100   { width: 100% !important; max-width: none !important; }
.oh     { overflow: hidden !important; }

.tt-u   { text-transform: uppercase; }
.tt-l   { text-transform: lowercase;}
.tt-n   { text-transform: none !important;}
.bs-n   { box-shadow: none !important }