$ns: modal;

.#{$ns} {
    background-color: $component-overlay-bg;
    display: none;
    height: 100%;
    overflow: auto;
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;

    // Modal Content
    &__content {
        max-height: 90%;
        width: 100%;
        max-width: 900px;

        margin: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @media (max-width: $bp-site-tp) {
            max-width: 90%;
        }

        // @media (max-width: $bp-site-tp) {
        //     max-width: 90%;
        // }
    }

    // Modal Close
    &__close {
        color: inherit;
        position: absolute;
        right: $elements-spacing;
        top: $elements-spacing;
        z-index: 14;
        background: none !important;
        cursor: pointer;
    }

    // Modal Content Wrapper
    &__content-wrapper {
        box-shadow: $component-box-shadow;
        background-color: #fff;
        padding: 30px;
        position: relative;
        // height: 100%;
        overflow: hidden;
        overflow-y: auto;

        @media (max-width: $bp-site-tp) {
            padding: 15px;
        }
    }

    // Modal Header
    &__header {
        margin-bottom: 9px;
        margin-top: 0;
    }

    // Captcha target
    // Using !important to override inline styles
    .grecaptcha-badge {
        position: absolute !important;
        right: -186px !important;
    }

    // Numinix Buttons
    .buttons-container {
        text-align: center;
    }
}

.#{$ns}--secondary {
    .#{$ns} {
        &__content {
            max-width: 500px;
        }
    }
}

// ---
// theme overwrites
@if (variable-exists(modal-overwrite)) {
    @if ($modal-overwrite) {
        @include modal;
    }
}
