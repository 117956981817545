// pixels to em
$browser-context: 16px; // Default

@function rem($pixels, $context: $browser-context) {
    @if ($pixels == "inherit") {
        @return inherit;
    } @else if(unit($pixels) == "rem") {
        @return $pixels;
    } @else {
        @if (unitless($pixels)) {
            $pixels: $pixels * 1px;
        }

        @if (unitless($context)) {
            $context: $context * 1px;
        }

        @return $pixels / $context * 1rem;
    }
}
