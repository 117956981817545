$component-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);

$body-color: #323332;

// ---
// wraps
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1232px,
    xxl: 1324px,
) !default;

// ---
// font
$font-family-base: "Montserrat", sans-serif;
$font-family-bold: "Montserrat", sans-serif;

$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$font-size-lg: ($font-size-base * 1.375);
$font-size-md: ($font-size-base * 1.125);
$font-size-sm: ($font-size-base * 0.875);
$font-size-xs: 12px;
$font-size-min: 10px; // smallest it can be
$font-size-base-px: 16px;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;

// ---
// Links
$enable-link-bg-underline: false;

$link-color: #6ab131;
$link-bg-hover: transparent;
$link-color-hover: darken($link-color, 10%);

// ---
// headings
$heading-text-transform: none;

$h1-font-size: 62px;
$h2-font-size: 30px;
$headings-line-height: 1;

// ---
// components
$component-border-color: #ecedee;

// ---
// dropdown
$dropdown-trigger-padding-x-right: 20px;
// $dropdown-container-enable-arrow-top: false;

// ---
// Forms
$input-enable-border-bottom-only: false;

// $input-select-bg-image: "/img/select_arrow_chevron-down.svg";
// $input-select-bg-size-height: 9px;
// $input-select-bg-size-width: 16px;

$input-color: #595a5a;
$input-border-color: #c4c4c4;
$input-border-radius: 3px;

$input-textarea-height: 150px;

$custom-control-top: 3px;
$custom-control-indicator-size: 1.125rem;
$custom-control-gutter: 1.75rem;
// $custom-control-indicator-checked-bg: #f26d21;
// $custom-control-indicator-active-bg: #f26d21;

// ---
// button
$button-bg: #6ab131;
$button-bg-hover: darken($button-bg, 10%);

$button-height: 48px;
$button-border-radius: 3px;

$button-font-weight: 600;

// $button-indicator-icon-checked-height: 14px;
// $button-indicator-icon-checked-width: 16px;

// ---
// sidebar
$sidebar-width: 21.6666%;
// $sidebar-nav-item-font-size: $font-size-md;
// $sidebar-nav-item-line-height:
$sidebar-nav-item-spacing-y: 0.5rem;
// $sidebar-nav-item-child-spacing-y: 15px;

// ---
// product listing
$product-listing-spacing-y: 45px;
$product-listing-hover-effect: false;
$product-listing-inner-padding: 0;
$product-listing-per-row: 4;
$product-listing-addcart-show-only-hover: false;

// ---
// product listing title
$product-title-spacing-y: 9px;
// $product-title-font-size: $font-size-md;
$product-title-font-weight: $font-weight-normal;

// ---
// product listing price
$product-listing-price-spacing-y: 12px;
// $product-price-font-size: 24px;
// $product-price-font-weight: $font-weight-medium;
// $product-price-color: #f26d21;
// $product-price-normal-color: #646464;
// $product-price-normal-font-weigth: $font-weight-light;
$product-price-special-color: #d91a1a;
$product-price-special-font-weight: $font-weight-bold;
$product-price-off-color: #808182;

// order
$product-listing-review-order: 10;

//
// ---
// product badge
$product-badge-padding-y: 0;
$product-badge-padding-x: 0;
$product-badge-sale-bg: #fff;
$product-badge-new-bg: none no-repeat 0 0;
$product-badge-border-width: 1px;
$product-badge-border-style: solid;
$product-badge-border-color: #d91a1a;
$product-badge-border-radius: 50%;
$product-badge-color: #d91a1a;
$product-badge-font-family: $font-family-bold;
$product-badge-font-size: 11px;
$product-badge-font-weight: 700;
$product-badge-line-height: 1.15;
$product-badge-position-x: 0;
$product-badge-position-y: 0;

// stars
$stars-letter-spacing: 1px;
$product-listing-review-spacing-y-top: 10px;
// $product-listing-review-spacing-y-bottom: 0;

// messages
$message-padding-y: 12px;
$message-padding-x: 15px;

// inputs
// $input-color: #3c3c3c;
$input-height: 48px;
// $input-border-radius: 0;

// ---
// breadcrumb
$breadcrumb-spacing-y: 45px;
$breadcrumb-font-size-active: inherit;

// ---
// tables
// $table-bordered: true;
// $table-striped: true;
// $table-header-bg: #f2f5f9;
$table-header-font-weight: 700;
$table-header-font-size: $font-size-sm;
// $table-row-stripped-bg: #f2f5f9;

// ---
// stars
$stars-color: #efce4a;
$stars-color-off: #f1f1f1;

// ---
// save for later
$save-for-later-trigger-color: #ccc;
$save-for-later-trigger-color-hover: #f26d21;

// ---
// card
// $card-spacing-y-top: $elements-spacing-lg + ($elements-spacing-lg / 2);

$card-padding-x: 0;
$card-padding-y: 0;
$card-border-radius: 0;
$card-border-width: 0;
$card-border-color: none;

$card-header-footer-padding-y: 0;
$card-header-border-bottom-width: 0;
$card-header-font-size: 24px;
$card-header-font-weight: 600;
$card-header-margin-bottom: 16px;
$card-header-bg: transparent;
$card-header-color: inherit;
$card-box-shadow: none;

// tabs
$tab-nav-border-bottom-only-enable: false;

$tab-nav-spacer-y: 0;
$tab-nav-spacer-x: 0;
$tab-nav-margin-y-bottom: 0;
$tab-nav-padding-y: 21px;
$tab-nav-padding-x: 21px;

// $tab-nav-border-color-select: #fff;

$tab-nav-bg-hover: darken(#fff, 2%);
$tab-nav-font-size: 1rem;
$tab-nav-font-weight: 600;

$tab-panel-padding-y: 18px;
$tab-panel-padding-x: 18px;
$tab-panel-border-width: 1px;

// ---
// save for later
$save-for-later-trigger-icon-height: 24px;
$save-for-later-trigger-icon-width: 24px;
$save-for-later-trigger-color: #b7b7b7;
$save-for-later-trigger-color-hover: #85b040;

// ---
// flyout
$flyout-bg: #323332;

// ---
// autocomplete
$autocomplete-loading-enable: true;
$autocomplete-item-bg-hover: #e7f5dc;

// ---
// footer
$footer-bg: #1b2530;
$footer-link-color: #b9c8d9;
$footer-link-color-hover: $link-color-hover;

$feedback-padding-x: 98px;

// ---
// messages
$message-success-bg: #e7f5dc;
$message-success-border-color: #d6e9c6;
$message-success-color: #3c763d;

// ---
// components overwrites
$address-list-overwrite: false;
$autocomplete-overwrite: false;
$blockUI-overwrite: false;
$breadcrumb-overwrite: true;
$buttons-overwrite: true;
$cards-overwrite: true;
$carousel-overwrite: true;
$cart-mini-overwrite: false;
$cart-overwrite: false;
$checkout-overwrite: true;
$countdown-overwrite: false;
$custom-radio-checkbox-overwrite: true;
$datepicker-overwrite: true;
$dropdown-overwrite: true;
$fancybox-overwrite: false;
$feedbacks-overwrite: true;
$flyout-overwrite: false;
$modal-overwrite: false;
$nav-overwrite: true;
$pagination-overwrite: false;
$popup-overwrite: true;
$products-overwrite: true;
$save-for-later-overwrite: true;
$search-overwrite: true;
$sidebar-overwrite: true;
$slider-overwrite: false;
$stars-overwrite: false;
$tabs-overwrite: true;
$tooltip-overwrite: false;

// ---
// pages overwrites
$page-about-overwrite: true;
$page-account-overwrite: true;
$page-account-overview-overwrite: true;
$page-account-contact-overwrite: false;
$page-account-lists-overwrite: false;
$page-account-payment-info-overwrite: true;
$page-account-payment-info-edit-overwrite: true;
$page-address-book-overwrite: false;
$page-blog-overwrite: true;
$page-cart-overwrite: true;
$page-checkout-success-overwrite: true;
$page-contact-us-overwrite: true;
$page-create-account-overwrite: false;
$page-down-for-maintenance-overwrite: false;
$page-error-404-overwrite: false;
$page-login-overwrite: true;
$page-order-details-overwrite: false;
$page-order-history-overwrite: true;
$page-password-forgotten-overwrite: false;
$page-recurring-orders-edit-overwrite: false;
$page-recurring-orders-overwrite: false;
$page-reviews-all-overwrite: true;
$page-testimonials-overwrite: false;
$page-checkout-overwrite: true;

// --
// V2 pages
$page-order-history-v2-overwrite: true;
