// Dropdown
//
// Base class takes care of all the key behavioral aspects.

// ---
// styles
.dropdown {
    position: relative;
    z-index: 3;

    // trigger wrapper
    &-trigger {
        text-decoration: none;
    }

    // trigger dropdown arrow
    &-arrow {
        background: url($dropdown-trigger-image) no-repeat right center;
        padding-right: $dropdown-trigger-padding-x-right;
        background-size: auto $dropdown-trigger-image-height;
        position: relative;
        z-index: 2;
        display: inline-block;
        position: relative;
    }

    &-arrow--disabled {
        background: none;
        padding-right: 0;
    }

    // container
    &-container {
        display: none;
        position: absolute;
        left: 50%;
        top: 100%;
        transform: translateX(-50%);
        // min-width: 100%;
        
        @if ($dropdown-container-enable-arrow-top) {
            padding-top: $dropdown-container-padding-top;

            &::before {
                content: "";
                background: url($dropdown-container-arrow-img) no-repeat 0 0;
                background-size: cover;
                
                filter: drop-shadow($dropdown-container-arrow-box-shadow);
                
                height: $dropdown-container-arrow-height;
                width: $dropdown-container-arrow-width;

                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                
                z-index: 1;
            }
        }

        // right
        &--right {
            right: 0;
            left: auto;
            transform: none;

            @if ($dropdown-container-enable-arrow-top) {

                &::before {
                    left: auto;
                    right: $dropdown-container-arrow-position-right;
                    transform: none;
                }
            }
        }

        // left
        &--left {
            right: auto;
            left: 0;
            transform: none;

            @if ($dropdown-container-enable-arrow-top) {

                &::before {
                    left: $dropdown-container-arrow-position-left;
                    transform: none;
                }
            }
        }
    }

    &-list {
        @include list-unstyled;
        background: $dropdown-list-bg;
        box-shadow: $dropdown-list-box-shadow;
        border-radius: $dropdown-list-border-radius;
        margin: 0;
        padding: $dropdown-list-padding;
        position: relative;

        li {
            margin-top: 0;
        }

        li > a,
        li > span {
            background: $dropdown-list-item-bg;
            color: $dropdown-list-item-color;
            cursor: pointer;
            display: block;
            padding: $dropdown-list-item-padding;
            text-decoration: none;
            white-space: nowrap;

            &:hover {
                color: $dropdown-list-item-color-hover;
                background: $dropdown-list-item-bg-hover;
            }
        }
    }

    // remove br
    br {
        display: none;
    }
}

// ---
// states
.dropdown:hover {
    .dropdown-arrow::before {
        display: block;
    }

    .dropdown-container {
        display: block;
    }
}

// ---
// theme overwrites
@if (variable-exists(dropdown-overwrite)) {
    @if ($dropdown-overwrite) {
        @include dropdown;
    }
}
