// ---------
// pre-header/banner
// .pre-header {
// 	font-size: 14px;
// 	background-color: #e7f5dc;
// 	color: #6ab131;
// 	font-weight: $font-weight-semi-bold;
	
// 	.wrapper {
// 		padding-top: 9px;
// 		padding-bottom: 9px;
// 		text-align: center;
// 	}
// }


// -------
// fda message/nicotine warning
.fda-message {
	background: #fff;
	font-weight: bold;
	font-size: rem(24);
	text-align: center;
	padding: rem(18) 15px;

	@media (min-width: 1024px) {
		font-size: rem(40);
		line-height: 1;
	}
}


// ---------
// header
[role="banner"] {
	position: relative;
	z-index: 3;
}

[role="banner"] {
	background-color: #f4f6f9;

	.wrapper {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		padding: 9px 0 15px;
	}

	a:hover {
		text-decoration: none;
	}

	@media (min-width: 1024px) {
		.wrapper {
			padding-bottom: 9px;
		}
	}
}

// ---------
// logo
.logo-header {
	width: 150px;

	// responsive
	@media (min-width: 414px) {
		width: 200px;
	}

	@media (min-width: 1024px) {
		width: auto;
	}
}


// ---------
// search/languages
.search-languages-wrapper {
	display: flex;
	order: 3;
	margin-top: 12px;
	width: 100%;

	@media (min-width: 1024px) {
		margin-left: 40px;
		order: 0;
		margin-top: 0;
		width: auto;
	}
}


// ---------
// languages
.nav-languages {
	list-style: none;
	padding: 0;
	margin: 0;
	margin-left: 20px;
	display: flex;

	li + li {
		margin-left: 10px;
	}

	a {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 44px;
		height: 44px;
		border-radius: 5px;
		border: 1px solid #e8e8e8;
	}

	a:hover,
	a.is-active {
		border-color: #6ab131;
	}

	a.is-active:hover {
		pointer-events: none;
	}
}


// ---------
// nav tools
.nav-tools {
	list-style: none;
	margin-left: auto;
	display: flex;
	align-items: center;
	font-size: 14px;

	&__link {
		color: inherit;
		display: flex;
		font-weight: $font-weight-medium;
		align-items: center;
		text-decoration: none;
	}

	li + li {
		margin-left: 21px;
	}

	a:hover {
		color: #6ab131;
	}

	&__text {
		display: none;
		margin-left: 9px;
	}

	svg {
		color: #6ab131;
	}

	// responsive
	@media (min-width: 1280px) {
		li + li {
			margin-left: 30px;
		}

		&__text {
			display: block;
		}
	}
}

// cart
.nav-tools__link-cart {
	> span {
		margin-left: 24px;
		font-size: 14px;
		font-weight: $font-weight-bold;
		line-height: 1;
	}

	small {
		display: block;
		font-weight: $font-weight-normal;
		margin-top: 6px;
		font-size: 12px;
	}

	@media (min-width: 1280px) {
		border: 1px solid #6ab131;
		border-radius: 5px;
		padding: 6px 27px 6px 15px;
	}

	// cart count
	.cart-count {
		display: flex;
		position: absolute;
		border-radius: 50%;
		background-color: #d91a1a;
		height: 18px;
		width: 18px;
		text-align: center;
		color:#fff;
		font-weight: $font-weight-semi-bold;
		font-size: $font-size-xs;
		justify-content: center;
		align-items: center;
		margin-left: 11px;
		margin-top: -15px;
	}
}

// mobile menu
.nav-tools__item-menu {
	display: block;

	@media (min-width: 1024px) {
		display: none;
	}
}

.nav-tools__link-menu {
	display: block;
	padding: 3px 6px;
	border-radius: 5px;
	border: 1px solid #6ab131;
}


// ---------
// nav
nav[role="navigation"].nav {
	background: #323332;
	display: none;
	position: relative;
	z-index: 2;

	.wrapper {
		height: 60px;
		display: flex;
		align-items: center;
	}

	a {
		color: #f4f6f9;
		font-size: 14px;
	}

	[class*="nav__item"]:hover > a {
		color: #6ab131;
		text-decoration: none;
	}

	// categories
	ul {
		list-style: none;
		padding-left: 0;
		margin: 0;
	}

	li {
		position: relative;
	}

	.nav__list--1 {
		display: flex;
	}

	.nav__item--1:not(:last-of-type) {
		margin-right: 40px;
	}

	.dropdown-container {
		left: -20px;
	}

	[class*="nav__item"]:hover > .dropdown-container {
		display: block;
	}

	// blog
	.nav__link--blog {
		margin-left: auto;
		display: flex;

		svg {
			color: #6ab131;
			margin-right: 9px;
		}
	}

	@media (min-width: 1024px) {
		display: block;
	}
}

// LEXC-114
[role="banner"] {
    cursor: default;
}