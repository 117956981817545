// Making changes to this file
// 
// 1. Enable the overwrite variable related to this component in _variables.scss under "component overwrites"
// 2. Changes here will only affect the component in all pages its been added

@mixin popup {
    

    // age
    .age {
        .popup__logo {
            display: block;
            margin: 0 auto 30px;
        }

        .popup__title {
            font-size: rem(24);
            border-top: $component-border-width $component-border-style $component-border-color;
            padding-top: $elements-spacing-lg;
            text-align: center;
        }

        .popup__buttons {
            display: flex;
            justify-content: space-between;
            margin-bottom: 27px;
        }

        .button {
            width: calc(50% - 5px);
            padding-left: $button-padding-x-small;
            padding-right: $button-padding-x-small;
        }

        @media (min-width: 767px) {
            padding-left: $button-padding-x;
            padding-right: $button-padding-x;
        }
        
    }
}