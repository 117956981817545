/* tables */
table {
    background: $table-bg;
    width: 100%;

    td,
    th {
        padding: $table-cell-padding-y $table-cell-padding-x;
        text-align: left;
        vertical-align: middle;
    }

    th {
		white-space: nowrap;
		font-family: $table-header-font-family;
		font-weight: $table-header-font-weight;
    }

    // ---
    // head
    thead {
        th {
            background: $table-header-bg;
            counter-reset: $table-header-color;
            font-size: $table-header-font-size;
            text-transform: $table-header-text-transform;

            @if ($table-header-bg == 'none') {
                padding-top: 0;
            }
        }
    }

    // ---
    // body
    tbody {
        td,
        th {
            border-top: $table-border-width $table-border-style $table-border-color;
            font-size: $table-body-font-size;
        }
    }

    // ---
    // Add borders all around the table.
    @if ($table-bordered) {
        border: $table-border-width $table-border-style $table-border-color;
        border-radius: $table-border-radius;
    } @else {
        border: 0;
        border-bottom: $table-border-width $table-border-style $table-border-color;

        tr > *:first-child {
            padding-left: 0;
        }

        tr > *:last-child {
            padding-right: 0;
        }
    }

    // ---
    // Zebra-striping
    @if ($table-striped) {
        tbody {
            td,
            th {
                border: 0;
            }

            tr:nth-of-type(even) {
                background: $table-row-stripped-bg;
            }
        }
    }

    // ---
    // foot
    tfoot {
        border-top: $table-border-width $table-border-style $table-border-color;

        td,
        th {
            border-top: none;
        }

        tr:not(:first-child) td,
        tr:not(:first-child) th {
            padding-top: 5px;
        }

        tr:not(:last-child) td,
        tr:not(:last-child) th {
            padding-bottom: 5px;
        }
    }
}

// ---
// responsive
@media (max-width: $bp-site-ml) {
    .table--responsive {
        @include table-responsive;
    }
}
