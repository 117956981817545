.buttons-container {
	display: flex;
	  align-items: center;
	flex-wrap: wrap;
	margin-top: rem($elements-spacing-lg);

	a.button {
		text-decoration: none;
	}

	& + & {
		border-top: $component-border-width $component-border-style $component-border-color;
		padding-top: $elements-spacing * 2;
	}

	// ---
	// horizontal
	> * + * {
		margin-left: $elements-spacing;
	}

	// ---
	// vertical
	&--stacked {
		@include button-stack;
  }
  

  // ---
  // responsive
  @media (max-width: 576px) {
    &.buttons-container--stacked-mb {
      @include button-stack;
    }
  }
}

[class*="buttons-container"] {
	// ---
	// checked mark
	.checked {
		background: $button-indicator-icon-checked no-repeat 0 0;
		background-size: cover;
		display: inline-block;
		height: $button-indicator-icon-checked-height;
		margin-right: 6px;
		width: $button-indicator-icon-checked-width;
	}
}

// ---------------------
// button
.button {
	background: $button-bg;
	border: $button-border-width $button-border-style $button-border-color;
	border-radius: $button-border-radius;
	box-shadow: $button-box-shadow;
	color: $button-color;
	cursor: pointer; /* Improves usability and consistency of cursor style between image-type 'input' and others */
	display: inline-block;
	font-size: $button-font-size;
	font-family: $button-font-family;
	font-weight: $button-font-weight;
	height: $button-height;
	line-height: $button-line-height;
	text-transform: $button-text-transform;
	text-decoration: none;
	padding: $button-padding-y $button-padding-x;
	position: relative;
	outline: none;
	text-align: center;
	width: auto;

	// ---
	// hover/focus
	&:hover,
	&:focus {
		color: $button-color-hover;
		border-color: $button-border-color-hover;
		background: $button-bg-hover;
		text-decoration: none;
	}

	// ---
	// small
	&--sm {
		font-size: $button-font-size-small;
		height: $button-height-small;
		line-height: $button-line-height-small;
		padding: $button-padding-y-small $button-padding-x-small;
	}

	// ---
	// loading
	&.loading {
		cursor: wait;
		pointer-events: none;
		opacity: .75;
		transition: all .5s;
	}
}

.button--secondary {
	background: $button-secondary-bg;
	border-color: $button-secondary-border-color;
	color: $button-secondary-color;

	&:hover,
	&:focus {
		background: $button-secondary-bg-hover;
		border-color: $button-secondary-border-color-hover;
		color: $button-secondary-color-hover;
	}
}

// ---
// social/button with icons
.button--facebook {
	background: $button-facebook-bg;
	border-color: $button-facebook-bg;

	&:hover,
	&:focus {
		background: $button-facebook-bg-hover;
		border-color: $button-facebook-bg-hover;
	}
}

.button--google {
	background: $button-google-bg;
	border-color: $button-google-bg;

	&:hover,
	&:focus {
		background: $button-google-bg-hover;
		border-color: $button-google-bg-hover;
	}
}

.button {
	// ---
	// social
	&--icon {
		&::before {
			content: '';
			background-position: center;
			background-repeat: no-repeat;
			height: 23px;
			width: 38px;
			position: absolute;
			  left: 15px;
			  top: 50%;
			transform: translateY(-50%);
		}
	}

	// ---
	// facebook
	&--facebook {
		&::before {
			background-image: url('/img/facebook.svg');
			background-size: contain;
			background-position: 6px center;
		}
	}

	// ---
	// google
	&--google {
		&::before {
			background-image: url('/img/google-plus.svg');
		}
  	}
  
	// ---
	// disabled
	&[disabled],
	&.is-loading {
		pointer-events: none;
		opacity: .7;
	}
}


// ---
// theme overwrites
@if (variable-exists(buttons-overwrite)) {
  @if($buttons-overwrite) {
    @include buttons;
  }
}