[data-toggle='tooltip'] {
	background: url(/img/icon_help.png) no-repeat 0 0;
	display: inline-block;
	position: relative;
	vertical-align: bottom;
	height: $tooltip-height;
	width: $tooltip-width;
	margin-left: 6px;
	margin-top: 0;

	&::before,
	&::after {
		display: none;
		background-color: $tooltip-bg;
		border: $tooltip-border-color;
	}

	&::before {
		box-shadow: $tooltip-box-shadow;
		border-radius: $tooltip-border-radius;
		content: attr(title);
		color: inherit;
		font-size: $tooltip-font-size;
		line-height: $tooltip-line-height;
		padding: $tooltip-padding;
		position: absolute;
		right: -9px;
		bottom: 175%;
		width: 320px;
		white-space: break-spaces;
	}

	&.tooltip-center::before {
		right: auto;
		left: 50%;
		transform: translateX(-50%);
	}

	&.tooltip-left::before {
		right: auto;
		left: -9px;
	}

	&::after {
		border-top: none;
		border-right: none;
		content: '';
		position: absolute;
		right: 20%;
		bottom: 140%;
		height: 15px;
		width: 15px;
		transform: rotate(-45deg);
	}

	&:hover::before,
	&:hover::after {
		display: block;
	}
}

.tooltip-wrapper {
	display: block;
	position: relative;

	[data-toggle='tooltip'] {
		position: absolute;
		margin: 0;
		right: 9px;
		top: 50%;
		transform: translateY(-50%);

		@if ($input-enable-border-bottom-only) {
			right: 0;
		}
	}
}

// ---
// theme overwrites
@if (variable-exists(tooltip-overwrite)) {
	@if ($tooltip-overwrite) {
		@include tooltip;
	}
}
