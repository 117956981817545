/* search */
.search {
	margin: 0;
	position: relative;
	width: 100%;

	// entry
	input[type="search"] {
		font-size: rem(18);
		height: 45px;
		padding-right: 50px;
		max-width: none;
		width: 100%;
	}

	// submit button
	button {
		background: none;
		border: none;
		box-shadow: none;
		display: flex;
		  align-items: center;
		  justify-content: center;
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		width: 45px;
		text-align: center;
		padding: 0;

		svg {
			fill: currentColor;
			height: 20px;
			width: auto;
		}

		&:hover {
			background: none;
		}
	}

	// close button
	&__close {
		display: none;
	}
}

#search-fake-button.is-active {
	color: $white;
}
#search-wrapper {
	background: $bg-search-wrapper;
	position: absolute;
	top: 100%;
	left: 0;
	right: 0;
	top: 100%;
	display: none;

	.wrapper:after {
		content: "";
		background: $bg-search-wrapper;
		position: absolute;
		top: -81px;
		right: 0;
		width: 46px;
		height: 81px;
		right: 2px;
		
		@media (max-width: $bp-site-tp) {
			top: auto;
	    	bottom: 100%;
	    	right: 79px;
	    	height: 52px;
			width: 38px;
		}
	}

	&.in-focus {
		z-index: 1;
	}

	form {
		position: relative;
		display: block !important;
		max-width: 610px;
		margin: 30px auto;
		
		@media (max-width: $bp-site-tp) {
			margin-top: 14px;
			margin-bottom: 14px;
		}
		
	 	input[type="search"] {
			background: #b3b3b3;
			color: #010101;
			border: none;
			display: block;
			font-size: 24px;
			height: 60px;
			padding: 0 9px;
			width: 100%;
			max-width: none;
			text-align: center;
			position: relative;
			z-index: -1;
			
			@media (max-width: $bp-site-tp) {
				font-size: 18px;
    		height: 35px;
			}
		
			&::placeholder {
	   			color: #010101;
	   			opacity: 1;
			}

			&.is-visible {
				width: 100%;
				z-index: 1;
			}
		}
 	
		*[type="submit"] {
			background: none;
			border: none;
			color: #000;
			cursor: pointer;
			font-size: 16px;
			padding: 0 9px;
			margin: 0;
			height: 33px;
			position: absolute;
			right: 0;
			top: 0;
			z-index: 2;
			visibility: hidden;
			
			&.is-active {
				color: #fff;
			}
			
			&.is-visible {
				visibility: visible;
			}
		}
	}
}


// ---
// theme overwrites
@if (variable-exists(search-overwrite)) {
  @if($search-overwrite) {
    @include search;
  }
}