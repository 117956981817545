// body
.is-ovhidden {
    overflow: hidden;
}

// flyout nav
.flyout {
    background: $flyout-bg;
    display: none;
    height: 100%;
    overflow: hidden;
    position: fixed;
    top: 0;
    z-index: 9999;
    width: $flyout-width;
    -webkit-overflow-scrolling: touch; /* enables momentum scrolling in iOS overflow elements */

    transform: translate3d(-$flyout-width, 0, 0);
    transition: transform 0.2s cubic-bezier(0.16, 0.68, 0.43, 0.99);

    // opened
    &.is-slided {
        transform: translate3d(0, 0, 0);
    }
}

/* makes it visible just after loading all things */
.flyout.is-visible {
    display: block;
}

.is-ie .flyout,
.is-msie .flyout {
    display: none;
}

//
.flyout {
    nav,
    .nav__inner {
        background: $flyout-bg;
        height: 100%;
        overflow: auto;
        overflow-x: hidden;
        padding: $flyout-padding-y $flyout-padding-x;
    }

    // lists
    ul {
        @include clear-ul;
        overflow: auto;
        overflow-x: hidden;
    }

    ul + * {
        border-top: 1px solid lighten($flyout-bg, 35%);
        margin-top: 20px;
        padding-top: 28px;
    }

    // ---
    // items
    li {
        margin-top: 0;
    }

    // ---
    // links
    a {
        background: $flyout-link-bg;
        color: $flyout-link-color;
        line-height: $flyout-link-line-height;
        text-decoration: none;
        display: block;
        padding: $flyout-link-padding-y $flyout-link-padding-x-right $flyout-link-padding-y $flyout-link-padding-x-left;
        position: relative;
        text-decoration: $flyout-link-text-decoration;

        &:hover {
            text-decoration: $flyout-link-text-decoration;
        }
    }

    // ---
    // arrow
    .icon__arrow--right {
        position: absolute;
        right: 0;
        top: $flyout-icon-position-y;

        svg {
            fill: $flyout-icon-color;
            height: $flyout-icon-height;
            width: auto;
        }
    }

    // ---
    // sub header
    .sub__header {
        border-bottom: 1px solid lighten($flyout-bg, 35%);
        display: block;
        margin-bottom: 10px;
        padding-bottom: 10px;
        position: relative;
    }

    .sub__title {
        font-weight: bold;
    }

    .sub__btn__all {
        font-size: 0.8em;
        margin-left: 0;
        padding-top: 0;
    }

    .sub__btn__back {
        position: absolute;
        top: 0;
        right: 0;
        transform: rotate(-180deg);

        svg {
            fill: $flyout-icon-color;
            height: $flyout-icon-height;
            width: auto;
        }
    }

    // sub categories
    .sub__container {
        height: 100%;
        overflow: auto;
        overflow-x: hidden;
        position: fixed;
        right: 0;
        left: 0;
        bottom: 0;
        top: 0;
        z-index: 2;
        transform: translate3d(-$flyout-width, 0, 0);
        transition: transform 0.2s cubic-bezier(0.16, 0.68, 0.43, 0.99);
    }

    .sub__container.is-slided {
        transform: translate3d(0, 0, 0);
    }
}

.flyout__overlay {
    display: none;
}

.flyout.is-slided + .flyout__overlay {
    @include overlay;
    animation: fade 500ms;
}

/* keyframes */
@keyframes fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

// ---
// overwrites
.flyout {
    // currency
    select {
        padding-left: $input-padding-x;
    }

    .nav__level--2 {
        position: static;
        transform: none;

        > .nav__inner {
            padding: 0;

            > .sub__header {
                display: none;
            }
        }
    }
}

// ---
// theme overwrites
@if (variable-exists(flyout-overwrite)) {
    @if ($flyout-overwrite) {
        @include flyout;
    }
}
