// main
@import "../../../../../parent-theme/resources/assets/sass/base/index";

b, strong {
    font-weight: $font-weight-medium;
}

// header
@import "layout/header";

// footer
@import "layout/footer";

// components from parent
@import "../../../../../parent-theme/resources/assets/sass/components/flyout";
@import "../../../../../parent-theme/resources/assets/sass/components/buttons";
@import "../../../../../parent-theme/resources/assets/sass/components/autocomplete";
@import "../../../../../parent-theme/resources/assets/sass/components/search";
@import "../../../../../parent-theme/resources/assets/sass/components/newsletter";
@import "../../../../../parent-theme/resources/assets/sass/components/popup";
@import "../../../../../parent-theme/resources/assets/sass/components/dropdown";
@import "../../../../../parent-theme/resources/assets/sass/components/cards";
@import "../../../../../parent-theme/resources/assets/sass/components/breadcrumb";
@import "../../../../../parent-theme/resources/assets/sass/components/modal";

// extras
.text-screen-reader {
    @include sr-only;
}

// message fda
.message--us-fda-approval {
    font-weight: 600;
    margin-bottom: 0;
    text-align: center;
    padding: 12px 9px;
    padding-top:15px;
    background: #303130;
    color: #6AB131;
    font-size: 14px;
    line-height: 16px;
    height: auto;
    box-sizing: border-box;
    position: absolute;
    z-index:300;
    display: flex;
    justify-content: center;
    width: 100%;
    bottom: 0;
    right: 0;

    > span {
        margin-right: 10px;
    }
}