h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    margin-top: 0;
    text-transform: $heading-text-transform;
}

h1 {
    display: none;
}

h1,
.h1 {
    font-size: rem($h1-font-size);
    line-height: calc(#{rem($h1-font-size)} + #{$heading-line-height});
    margin-bottom: 0.775em;
}

h2,
.h2 {
    font-size: rem($h2-font-size);
    line-height: calc(#{rem($h2-font-size)} + #{$heading-line-height});
}

h3,
.h3 {
    font-size: rem($h3-font-size);
    line-height: calc(#{rem($h3-font-size)} + #{$heading-line-height});
}

h4,
h5,
h6,
.h4,
.h5,
.h6 {
    font-size: rem($h4-font-size);
}

.h-text {
    font-display: 1rem;
}
