a {
    @if ($enable-link-bg-underline) {
        background: linear-gradient(180deg, #{$link-bg-hover}, #{$link-bg-hover});
        background-size: 100% 2px;
        background-position: bottom;
        background-repeat: no-repeat;
        transition: background-size 0.1s;
        text-decoration: none;
    } @else {
        background: $link-bg;
        text-decoration: $link-decoration;
    }

    color: $link-color;
    display: inline-block;

    &:hover {
        background: $link-bg-hover;
        color: $link-color-hover;

        @if ($enable-link-bg-underline) {
            background-size: 100% 100%;
        } @else {
            text-decoration: $link-decoration-hover;
        }
    }
}
