@import "../mixins";

// based on jquery ui
.autocomplete {
    @include list-unstyled;
    background: $autocomplete-bg;
    border: $autocomplete-border-width $autocomplete-border-style
        $autocomplete-border-color;
    box-shadow: $autocomplete-box-shadow;
    border-radius: $autocomplete-border-radius;
    z-index: $autocomplete-z-index;
    position: absolute !important;
    max-height: $autocomplete-max-height;
    overflow-y: auto;

    a {
        background: none;
        cursor: pointer;
        color: $autocomplete-item-color;
        font-size: $autocomplete-font-size;
        line-height: $autocomplete-line-height;
        display: flex;
        overflow: hidden;
        padding: $autocomplete-padding-y $autocomplete-padding-x;
        text-decoration: none;

        br {
            display: none;
        }

        // prod image
        span:first-child {
            flex: $autocomplete-img-height;
            max-width: $autocomplete-img-width;
            overflow: hidden;
        }

        img {
            display: block;
            margin: 0 auto;
            max-height: $autocomplete-img-height;
        }

        // prod name
        & span:last-of-type {
            padding-left: $elements-spacing;
            flex: 1;
        }

        // hover, active
        &:hover,
        &.ui-state-active {
            background: $autocomplete-item-bg-hover;
        }
    }
}

// ---
// accessibity
.ui-helper-hidden-accessible {
    @include sr-only;
}

// ---
// loading
@mixin autocomplete-loading {
    &::before {
        content: "";
        display: none;
        background-color: $autocomplete-loading-bg-color;
        background-image: linear-gradient(
                $autocomplete-loading-bg-image-color 50px,
                transparent 0
            ),
            linear-gradient(
                100deg,
                rgba(255, 255, 255, 0),
                rgba(255, 255, 255, 0.5) 50%,
                rgba(255, 255, 255, 0) 80%
            ),
            linear-gradient(
                $autocomplete-loading-bg-image-color 20px,
                transparent 0
            ),
            linear-gradient(
                $autocomplete-loading-bg-image-color 20px,
                transparent 0
            );

        background-repeat: repeat-y;

        background-size: 50px 60px, /* image */ 50px 60px,
            /* highlight */ 150px 60px, 250px 60px;

        background-position: 0 0, /* circle */ 0 0, /* highlight */ 60px 0,
            60px 30px;

        position: absolute;
        left: 10px;
        right: 10px;
        bottom: 10px;
        top: 10px;

        animation: shine 1s infinite;
    }

    &.is-loading {
        min-height: 200px;
        overflow-y: hidden;
        max-height: $autocomplete-max-height;

        li {
            visibility: hidden;
        }

        &::before {
            display: block;
        }
    }
}

.autocomplete--loading {
    display: none;
}

@if (variable-exists(autocomplete-loading-enable)) {
    @if ($autocomplete-loading-enable) {
        .autocomplete--loading {
            position: absolute;
            left: 0;
            right: 0;

            &.is-loading {
                display: block;
            }

            @include autocomplete-loading;
        }

        .autocomplete {
            position: relative;
            @include autocomplete-loading;
        }
    }

    @keyframes shine {
        to {
            background-position: 0 0, 100% 0,
                /* move highlight to right */ 60px 0, 60px 30px;
        }
    }
}

// ---
// theme overwrites
@if (variable-exists(autocomplete-overwrite)) {
    @if ($autocomplete-overwrite) {
        @include autocomplete;
    }
}
