html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden],
template {
  display: none;
}

a {
  background-color: transparent;
}

a:active,
a:hover {
  outline: 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

mark {
  background: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 1em 40px;
}

hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
}

pre {
  overflow: auto;
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
}

input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  height: auto;
}

input[type='search'] {
  -webkit-appearance: textfield;
}

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0;
  padding: 0;
}

legend {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
}

optgroup {
  font-weight: bold;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

img {
  height: auto;
  max-height: 100%;
  width: auto;
  max-width: 100%;
}

iframe {
  display: block;
}

form,
fieldset {
  display: block;
  border: none;
}

.m0 {
  margin: 0 !important;
}

.mt0 {
  margin-top: 0 !important;
}

.mb0 {
  margin-bottom: 0 !important;
}

.ml0 {
  margin-left: 0 !important;
}

.mr0 {
  margin-right: 0 !important;
}

.p0 {
  padding: 0 !important;
}

.pt0 {
  padding-top: 0 !important;
}

.pb0 {
  padding-bottom: 0 !important;
}

.pl0 {
  padding-left: 0 !important;
}

.pr0 {
  padding-right: 0 !important;
}

.tac {
  text-align: center !important;
}

.tal {
  text-align: left !important;
}

.tar {
  text-align: right !important;
}

.bdn {
  border: none !important;
}

.db {
  display: block !important;
}

.dn {
  display: none !important;
}

.pen {
  pointer-events: none !important;
}

.pull-left {
  float: left !important;
}

.pull-right {
  float: right !important;
}

.clear-ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.w100 {
  width: 100% !important;
  max-width: none !important;
}

.oh {
  overflow: hidden !important;
}

.tt-u {
  text-transform: uppercase;
}

.tt-l {
  text-transform: lowercase;
}

.tt-n {
  text-transform: none !important;
}

.bs-n {
  box-shadow: none !important;
}

.wrapper {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: calc(100% - 30px);
  max-width: 1324px;
}

.wrapper .wrapper {
  margin-left: 0;
  margin-right: 0;
  width: auto;
}

@media (min-width: 576px) {
  .wrapper {
    width: calc(100% - 60px);
  }
}

@media (min-width: 1024px) {
  .wrapper {
    width: calc(100% - 120px);
  }
}

.wrapper--xsmall, .wrapper--small, .wrapper--medium, .wrapper--lg, .wrapper--xl {
  margin-left: auto;
  margin-right: auto;
}

.wrapper--xsmall {
  max-width: 426px;
}

.wrapper--small {
  max-width: 768px;
}

.wrapper--medium {
  max-width: 1024px;
}

.wrapper--lg {
  max-width: 1232px;
}

main {
  flex: 1 0 auto;
}

main > .wrapper {
  height: 100%;
}

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"), url(https://fonts.gstatic.com/s/roboto/v19/KFOmCnqEu92Fr1Mu4mxK.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local("Roboto Bold"), local("Roboto-Bold"), url(https://fonts.gstatic.com/s/roboto/v19/KFOlCnqEu92Fr1MmWUlfBBc4.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  src: local("Roboto Condensed"), local("RobotoCondensed-Regular"), url(https://fonts.gstatic.com/s/robotocondensed/v17/ieVl2ZhZI2eCN5jzbjEETS9weq8-19K7DQ.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

html {
  font-family: "Montserrat", sans-serif;
  font-size: 0.875rem;
  line-height: calc(1rem + 10px);
  font-weight: 400;
}

html.no-overflow {
  overflow: hidden;
}

@media (min-width: 720px) {
  html {
    font-size: 1rem;
    line-height: calc(1rem + 10px);
  }
}

body {
  background: #fff;
  overflow-x: hidden;
  color: #323332;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

a {
  background: transparent;
  text-decoration: none;
  color: #6ab131;
  display: inline-block;
}

a:hover {
  background: transparent;
  color: #528926;
  text-decoration: underline;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  margin-top: 0;
  text-transform: none;
}

h1 {
  display: none;
}

h1,
.h1 {
  font-size: 3.875rem;
  line-height: calc(3.875rem + 6px);
  margin-bottom: 0.775em;
}

h2,
.h2 {
  font-size: 1.875rem;
  line-height: calc(1.875rem + 6px);
}

h3,
.h3 {
  font-size: 1.5rem;
  line-height: calc(1.5rem + 6px);
}

h4,
h5,
h6,
.h4,
.h5,
.h6 {
  font-size: 1.125rem;
}

.h-text {
  font-display: 1rem;
}

ol,
ul {
  padding-left: 1rem;
}

ul,
ol,
dl,
p {
  margin: 0 0 0.5625rem 0;
}

li {
  margin: 0;
}

li + li {
  margin-top: 0.09375rem;
}

table {
  margin: 0.9375rem 0;
}

h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.625rem;
}

h2 {
  margin-top: 1.875rem;
}

h3,
h4,
h5,
h6 {
  margin-top: 1.5rem;
}

h2 + h3 {
  margin-top: 0;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.eztext a:not(.button) {
  color: #528926;
}

.form-group {
  margin: 0;
}

.form-group + .form-group {
  margin-top: 1rem;
}

label {
  display: block;
  margin: 0 0 0.25rem;
  width: auto;
}

select,
textarea,
input {
  background: #fff;
  color: #595a5a;
  display: block;
  font-size: inherit;
  font-family: inherit;
  border: 1px solid #c4c4c4;
  border-radius: 3px;
  height: 48px;
  margin: 0;
  padding-left: 0.555rem;
  padding-right: 0.555rem;
  width: 100%;
  max-width: 290px;
  min-width: 1px;
}

select.sm,
textarea.sm,
input.sm {
  font-size: 0.875rem;
  height: 34px;
  padding-left: 0.255rem;
  padding-right: 0.255rem;
}

textarea {
  height: 150px;
  max-width: none;
}

*[disabled] {
  background: #ccc;
}

select,
option {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
}

select {
  background-image: url(/img/select_arrow.svg);
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 10px 6px;
  padding-right: 36px;
}

select::-ms-expand {
  display: none;
}

.form-group--float-label {
  position: relative;
}

.form-group--float-label label {
  position: absolute;
  top: 0;
  font-size: 0.75rem;
  line-height: 1;
  font-weight: 700;
  left: 0;
  opacity: 0;
  transition: all 0.5s;
  z-index: 1;
}

.form-group--float-label label + input,
.form-group--float-label label + select,
.form-group--float-label label + textarea {
  transition: all 0.5s;
}

.form-group--float-label.show label {
  opacity: 1;
}

.form-group--float-label.show input,
.form-group--float-label.show select,
.form-group--float-label.show textarea {
  padding-top: 10px;
}

.form-group--float-label[class*='col-'] label {
  left: 12px;
}

.form-group--float-label select {
  background-position: right bottom 14px;
}

.form-group--float-label.form-group--select:not(.show) label {
  color: #b1b3b1;
  opacity: 1;
  font-size: inherit;
  font-weight: inherit;
  top: 13px;
}

.form-group__inline {
  display: flex;
}

input[type='radio'],
input[type='checkbox'] {
  height: auto;
  width: auto;
  margin: 0 6px 0 0;
}

.label-info {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.label-info > *:not(:last-child) {
  margin-right: 9px;
}

.form-group .tooltip-wrapper [data-toggle="tooltip"] {
  top: 24px;
  margin-top: -11px;
  transform: none;
}

.custom-control {
  position: relative;
  display: block;
  padding-left: 1.75rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-checkbox input,
.custom-radio input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-checkbox input:checked ~ label::before,
.custom-radio input:checked ~ label::before {
  color: none;
  background-color: #fff;
}

.custom-checkbox input:focus ~ label::before,
.custom-radio input:focus ~ label::before {
  box-shadow: none;
}

.custom-checkbox input:active ~ label::before,
.custom-radio input:active ~ label::before {
  color: none;
  background-color: #fff;
}

.custom-checkbox input:disabled ~ label,
.custom-radio input:disabled ~ label {
  color: #ccc;
}

.custom-checkbox input:disabled ~ label::before,
.custom-radio input:disabled ~ label::before {
  background-color: #ccc;
}

.custom-checkbox label,
.custom-radio label {
  position: relative;
  margin-bottom: 0;
  line-height: inherit;
}

.custom-checkbox label::before,
.custom-radio label::before {
  position: absolute;
  top: 3px;
  left: -1.75rem;
  display: block;
  border: 1px solid #c4c4c4;
  width: 1.125rem;
  height: 1.125rem;
  pointer-events: none;
  content: "";
  user-select: none;
  background-color: #fff;
}

.custom-checkbox label::after,
.custom-radio label::after {
  position: absolute;
  top: 3px;
  left: -1.75rem;
  display: block;
  width: 1.125rem;
  height: 1.125rem;
  content: "";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 80%;
}

.custom-checkbox label::before {
  border-radius: 0;
}

.custom-checkbox input:checked ~ label::before {
  background-color: #fff;
}

.custom-checkbox input:checked ~ label::after {
  background-image: url(/img/checkboxmark.svg);
}

.custom-checkbox.active > label::before {
  background-color: #fff;
}

.custom-checkbox.active > label::after {
  background-image: url(/img/checkboxmark.svg);
}

.custom-checkbox input:indeterminate ~ label::after {
  background-image: none;
}

.custom-checkbox input:disabled:checked ~ label::before {
  background-color: rgba(255, 255, 255, 0.5);
}

.custom-checkbox input:disabled:indeterminate ~ label::before {
  background-color: rgba(255, 255, 255, 0.5);
}

.custom-radio label::before {
  border-radius: 50%;
}

.custom-radio input:checked ~ label::before {
  background-color: #fff;
}

.custom-radio input:checked ~ label::after {
  background-image: url(/img/radiomark.svg);
}

.custom-radio input:disabled:checked ~ label::before {
  background-color: rgba(255, 255, 255, 0.5);
}

.custom-checkbox label::after,
.custom-radio label::after {
  background-position: left 2px top 1.5px;
}

[data-toggle='tooltip'] {
  background: url(/img/icon_help.png) no-repeat 0 0;
  display: inline-block;
  position: relative;
  vertical-align: bottom;
  height: 22px;
  width: 22px;
  margin-left: 6px;
  margin-top: 0;
}

[data-toggle='tooltip']::before, [data-toggle='tooltip']::after {
  display: none;
  background-color: #d1ecf1;
  border: #bee5eb;
}

[data-toggle='tooltip']::before {
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
  border-radius: 0;
  content: attr(title);
  color: inherit;
  font-size: 0.875rem;
  line-height: calc(0.875rem + 6px);
  padding: 12px 15px;
  position: absolute;
  right: -9px;
  bottom: 175%;
  width: 320px;
  white-space: break-spaces;
}

[data-toggle='tooltip'].tooltip-center::before {
  right: auto;
  left: 50%;
  transform: translateX(-50%);
}

[data-toggle='tooltip'].tooltip-left::before {
  right: auto;
  left: -9px;
}

[data-toggle='tooltip']::after {
  border-top: none;
  border-right: none;
  content: '';
  position: absolute;
  right: 20%;
  bottom: 140%;
  height: 15px;
  width: 15px;
  transform: rotate(-45deg);
}

[data-toggle='tooltip']:hover::before, [data-toggle='tooltip']:hover::after {
  display: block;
}

.tooltip-wrapper {
  display: block;
  position: relative;
}

.tooltip-wrapper [data-toggle='tooltip'] {
  position: absolute;
  margin: 0;
  right: 9px;
  top: 50%;
  transform: translateY(-50%);
}

.help-block {
  display: block;
  font-size: 0.875rem;
  line-height: calc(0.875rem + 6px);
  margin-top: 3px;
}

.help-block + .help-block {
  margin-top: 0;
}

.help-block.form-error,
.help-block.form-error--password {
  color: #d82f2e;
}

.help-block.form-success {
  color: green;
}

.help-inline {
  display: inline-block;
  margin-right: 10px;
}

.error {
  border-bottom-color: #d82f2e;
}

.message {
  border: 1px solid transparent;
  border-radius: 0;
  clear: both;
  margin: 0 0 24px;
  position: relative;
  max-width: none;
  padding: 12px 15px;
}

.message--success {
  background-color: #e7f5dc;
  border: #d6e9c6;
  color: #3c763d;
}

.message--error {
  background-color: #f2dede;
  border: #ebccd1;
  color: #a94442;
}

.message--attention {
  background-color: #d1ecf1;
  border: #bee5eb;
  color: #0c5460;
}

.message--text {
  background: none;
  border: none;
  padding: 0;
}

.message * {
  margin: 0;
}

.message:not(:empty) + .message {
  margin-top: -24px;
}

.message:empty {
  display: none;
}

/* tables */
table {
  background: #fff;
  width: 100%;
  border: 0;
  border-bottom: 1px solid #ecedee;
}

table td,
table th {
  padding: 9px 15px;
  text-align: left;
  vertical-align: middle;
}

table th {
  white-space: nowrap;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}

table thead th {
  background: none;
  counter-reset: inherit;
  font-size: 0.875rem;
  text-transform: uppercase;
  padding-top: 0;
}

table tbody td,
table tbody th {
  border-top: 1px solid #ecedee;
  font-size: inherit;
}

table tr > *:first-child {
  padding-left: 0;
}

table tr > *:last-child {
  padding-right: 0;
}

table tfoot {
  border-top: 1px solid #ecedee;
}

table tfoot td,
table tfoot th {
  border-top: none;
}

table tfoot tr:not(:first-child) td,
table tfoot tr:not(:first-child) th {
  padding-top: 5px;
}

table tfoot tr:not(:last-child) td,
table tfoot tr:not(:last-child) th {
  padding-bottom: 5px;
}

@media (max-width: 767px) {
  .table--responsive thead {
    display: none;
  }
  .table--responsive tbody,
  .table--responsive tbody tr,
  .table--responsive tbody td {
    display: block;
    border: none;
  }
  .table--responsive tr {
    padding-top: 9px;
    padding-bottom: 9px;
  }
  .table--responsive tbody tr {
    border-top: 1px solid #ecedee;
  }
  .table--responsive tbody tr td:first-child {
    border-top: none;
    padding-top: 0;
  }
  .table--responsive tbody tr td:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
  .table--responsive tbody td {
    display: block;
    padding-top: 5px;
    padding-bottom: 5px;
    position: relative;
    width: 100%;
  }
  .table--responsive tbody td[data-th] {
    padding-left: 110px;
  }
  .table--responsive tbody td[data-th]:before {
    content: attr(data-th);
    text-align: left;
    position: absolute;
    left: 0;
  }
  .table--responsive tfoot {
    display: table;
    width: 100%;
  }
  .table--responsive tfoot tr {
    border: none;
  }
  .table--responsive tfoot td {
    padding: 9px 15px;
    padding-left: 0;
    padding-right: 0;
  }
  .table--responsive tfoot tr td:first-child {
    padding-right: 10px;
    width: 90%;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -12px;
  margin-left: -12px;
  flex-direction: column;
}

.row + .row {
  margin-top: 24px;
}

.row [class^='col-'] {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 12px;
  padding-left: 12px;
}

.row [class^='col-'] input,
.row [class^='col-'] select,
.row [class^='col-'] textarea {
  max-width: none;
}

@media (min-width: 560px) {
  .row {
    flex-direction: row;
  }
  .row > .form-group {
    margin-top: 0;
  }
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.col-6 {
  flex: 0 0 100%;
  max-width: 100%;
}

.col-6 + .col-6 {
  margin-top: 1.3125rem;
}

@media (min-width: 560px) {
  .col-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-6 + .col-6 {
    margin-top: 0;
  }
}

b, strong {
  font-weight: 500;
}

.fda-message {
  background: #fff;
  font-weight: bold;
  font-size: 1.5rem;
  text-align: center;
  padding: 1.125rem 15px;
}

@media (min-width: 1024px) {
  .fda-message {
    font-size: 2.5rem;
    line-height: 1;
  }
}

[role="banner"] {
  position: relative;
  z-index: 3;
}

[role="banner"] {
  background-color: #f4f6f9;
}

[role="banner"] .wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 9px 0 15px;
}

[role="banner"] a:hover {
  text-decoration: none;
}

@media (min-width: 1024px) {
  [role="banner"] .wrapper {
    padding-bottom: 9px;
  }
}

.logo-header {
  width: 150px;
}

@media (min-width: 414px) {
  .logo-header {
    width: 200px;
  }
}

@media (min-width: 1024px) {
  .logo-header {
    width: auto;
  }
}

.search-languages-wrapper {
  display: flex;
  order: 3;
  margin-top: 12px;
  width: 100%;
}

@media (min-width: 1024px) {
  .search-languages-wrapper {
    margin-left: 40px;
    order: 0;
    margin-top: 0;
    width: auto;
  }
}

.nav-languages {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-left: 20px;
  display: flex;
}

.nav-languages li + li {
  margin-left: 10px;
}

.nav-languages a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
}

.nav-languages a:hover,
.nav-languages a.is-active {
  border-color: #6ab131;
}

.nav-languages a.is-active:hover {
  pointer-events: none;
}

.nav-tools {
  list-style: none;
  margin-left: auto;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.nav-tools__link {
  color: inherit;
  display: flex;
  font-weight: 500;
  align-items: center;
  text-decoration: none;
}

.nav-tools li + li {
  margin-left: 21px;
}

.nav-tools a:hover {
  color: #6ab131;
}

.nav-tools__text {
  display: none;
  margin-left: 9px;
}

.nav-tools svg {
  color: #6ab131;
}

@media (min-width: 1280px) {
  .nav-tools li + li {
    margin-left: 30px;
  }
  .nav-tools__text {
    display: block;
  }
}

.nav-tools__link-cart > span {
  margin-left: 24px;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
}

.nav-tools__link-cart small {
  display: block;
  font-weight: 400;
  margin-top: 6px;
  font-size: 12px;
}

@media (min-width: 1280px) {
  .nav-tools__link-cart {
    border: 1px solid #6ab131;
    border-radius: 5px;
    padding: 6px 27px 6px 15px;
  }
}

.nav-tools__link-cart .cart-count {
  display: flex;
  position: absolute;
  border-radius: 50%;
  background-color: #d91a1a;
  height: 18px;
  width: 18px;
  text-align: center;
  color: #fff;
  font-weight: 600;
  font-size: 12px;
  justify-content: center;
  align-items: center;
  margin-left: 11px;
  margin-top: -15px;
}

.nav-tools__item-menu {
  display: block;
}

@media (min-width: 1024px) {
  .nav-tools__item-menu {
    display: none;
  }
}

.nav-tools__link-menu {
  display: block;
  padding: 3px 6px;
  border-radius: 5px;
  border: 1px solid #6ab131;
}

nav[role="navigation"].nav {
  background: #323332;
  display: none;
  position: relative;
  z-index: 2;
}

nav[role="navigation"].nav .wrapper {
  height: 60px;
  display: flex;
  align-items: center;
}

nav[role="navigation"].nav a {
  color: #f4f6f9;
  font-size: 14px;
}

nav[role="navigation"].nav [class*="nav__item"]:hover > a {
  color: #6ab131;
  text-decoration: none;
}

nav[role="navigation"].nav ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

nav[role="navigation"].nav li {
  position: relative;
}

nav[role="navigation"].nav .nav__list--1 {
  display: flex;
}

nav[role="navigation"].nav .nav__item--1:not(:last-of-type) {
  margin-right: 40px;
}

nav[role="navigation"].nav .dropdown-container {
  left: -20px;
}

nav[role="navigation"].nav [class*="nav__item"]:hover > .dropdown-container {
  display: block;
}

nav[role="navigation"].nav .nav__link--blog {
  margin-left: auto;
  display: flex;
}

nav[role="navigation"].nav .nav__link--blog svg {
  color: #6ab131;
  margin-right: 9px;
}

@media (min-width: 1024px) {
  nav[role="navigation"].nav {
    display: block;
  }
}

[role="banner"] {
  cursor: default;
}

.footer {
  background-color: #323332;
  color: #ffffff;
  font-size: 14px;
  margin-top: 90px;
}

.footer a {
  color: inherit;
}

.footer a:hover {
  color: #528926;
  text-decoration: none;
}

.footer-nav-subscribe {
  display: flex;
  flex-wrap: wrap;
  margin-top: 37px;
  min-height: 209px;
}

@media (min-width: 1024px) {
  .footer-nav-subscribe {
    background-position: right 30px top 0;
  }
}

.footer-nav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 100%;
}

.footer-nav div + div {
  margin-top: 30px;
}

.footer-nav h2 {
  display: block;
  color: #6ab131;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 21px;
  margin-top: 0;
}

.footer-nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer-nav ul li:not(:first-child) {
  margin-top: 12px;
}

@media (min-width: 768px) {
  .footer-nav {
    flex-direction: row;
    width: 570px;
  }
  .footer-nav div + div {
    margin-top: 0;
  }
}

@media (min-width: 1366px) {
  .footer-nav {
    width: calc(46.9941%  - 70px);
    margin-right: 0;
  }
}

.footer-newsletter {
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.08);
  background-color: #595a5a;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 45px 0;
  padding: 51px 21px 57px;
  text-align: center;
  width: 100%;
}

.footer-newsletter form {
  width: 100%;
  max-width: 358px;
  text-align: center;
}

.footer-newsletter h2 {
  margin: 0 0 18px;
  font-weight: 600;
  font-size: 30px;
}

.footer-newsletter p {
  margin-top: 0;
  margin-bottom: 27px;
}

.footer-newsletter .form-group__inline {
  position: relative;
}

.footer-newsletter input {
  width: 100%;
  max-width: none;
}

.footer-newsletter .button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  height: auto;
  width: 50px;
  padding: 0;
}

@media (min-width: 1366px) {
  .footer-newsletter {
    position: relative;
    top: calc(-37px - 30px);
    margin: 0 10.2526% 0 auto;
    width: 39.2961%;
  }
}

.footer-site-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 45px;
}

@media (min-width: 768px) {
  .footer-site-info {
    flex-direction: row;
    justify-content: center;
  }
}

.footer-security {
  display: flex;
  align-items: center;
}

.footer-security img {
  height: 30px;
}

.footer-security * + * {
  margin-left: 10px;
}

@media (min-width: 414px) {
  .footer-security img {
    height: auto;
  }
  .footer-security * + * {
    margin-left: 10px;
  }
}

.footer-social {
  display: flex;
  margin-top: 30px;
}

.footer-social span {
  margin-right: 15px;
}

.footer-social ul {
  display: flex;
  align-items: center;
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.footer-social li + li {
  margin-left: 20px;
}

@media (min-width: 768px) {
  .footer-social {
    margin-left: auto;
    margin-top: 0;
  }
}

.footer-copyright {
  border-top: 1px solid #595a5a;
  margin-top: 30px;
  margin-bottom: 0;
  padding-top: 27px;
  padding-bottom: 27px;
  text-align: center;
  width: 100%;
}

.is-ovhidden {
  overflow: hidden;
}

.flyout {
  background: #323332;
  display: none;
  height: 100%;
  overflow: hidden;
  position: fixed;
  top: 0;
  z-index: 9999;
  width: 280px;
  -webkit-overflow-scrolling: touch;
  /* enables momentum scrolling in iOS overflow elements */
  transform: translate3d(-280px, 0, 0);
  transition: transform 0.2s cubic-bezier(0.16, 0.68, 0.43, 0.99);
}

.flyout.is-slided {
  transform: translate3d(0, 0, 0);
}

/* makes it visible just after loading all things */
.flyout.is-visible {
  display: block;
}

.is-ie .flyout,
.is-msie .flyout {
  display: none;
}

.flyout nav,
.flyout .nav__inner {
  background: #323332;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
  padding: 15px 15px;
}

.flyout ul {
  padding: 0;
  margin: 0;
  list-style: none;
  overflow: auto;
  overflow-x: hidden;
}

.flyout ul + * {
  border-top: 1px solid #8b8d8b;
  margin-top: 20px;
  padding-top: 28px;
}

.flyout li {
  margin-top: 0;
}

.flyout a {
  background: none;
  color: #fff;
  line-height: 1.35;
  text-decoration: none;
  display: block;
  padding: 5px 10px 5px 0;
  position: relative;
  text-decoration: none;
}

.flyout a:hover {
  text-decoration: none;
}

.flyout .icon__arrow--right {
  position: absolute;
  right: 0;
  top: 6px;
}

.flyout .icon__arrow--right svg {
  fill: currentColor;
  height: 15px;
  width: auto;
}

.flyout .sub__header {
  border-bottom: 1px solid #8b8d8b;
  display: block;
  margin-bottom: 10px;
  padding-bottom: 10px;
  position: relative;
}

.flyout .sub__title {
  font-weight: bold;
}

.flyout .sub__btn__all {
  font-size: 0.8em;
  margin-left: 0;
  padding-top: 0;
}

.flyout .sub__btn__back {
  position: absolute;
  top: 0;
  right: 0;
  transform: rotate(-180deg);
}

.flyout .sub__btn__back svg {
  fill: currentColor;
  height: 15px;
  width: auto;
}

.flyout .sub__container {
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  z-index: 2;
  transform: translate3d(-280px, 0, 0);
  transition: transform 0.2s cubic-bezier(0.16, 0.68, 0.43, 0.99);
}

.flyout .sub__container.is-slided {
  transform: translate3d(0, 0, 0);
}

.flyout__overlay {
  display: none;
}

.flyout.is-slided + .flyout__overlay {
  background-color: rgba(0, 0, 0, 0.5);
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9998;
  animation: fade 500ms;
}

/* keyframes */
@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.flyout select {
  padding-left: 0.555rem;
}

.flyout .nav__level--2 {
  position: static;
  transform: none;
}

.flyout .nav__level--2 > .nav__inner {
  padding: 0;
}

.flyout .nav__level--2 > .nav__inner > .sub__header {
  display: none;
}

.buttons-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 1.875rem;
}

.buttons-container a.button {
  text-decoration: none;
}

.buttons-container + .buttons-container {
  border-top: 1px solid #ecedee;
  padding-top: 20px;
}

.buttons-container > * + * {
  margin-left: 10px;
}

.buttons-container--stacked {
  flex-direction: column;
  align-items: stretch;
}

.buttons-container--stacked > *,
.buttons-container--stacked [class^="button"] {
  margin-left: 0;
  width: 100%;
}

.buttons-container--stacked > * + * {
  margin-top: 10px;
}

@media (max-width: 576px) {
  .buttons-container.buttons-container--stacked-mb {
    flex-direction: column;
    align-items: stretch;
  }
  .buttons-container.buttons-container--stacked-mb > *,
  .buttons-container.buttons-container--stacked-mb [class^="button"] {
    margin-left: 0;
    width: 100%;
  }
  .buttons-container.buttons-container--stacked-mb > * + * {
    margin-top: 10px;
  }
}

[class*="buttons-container"] .checked {
  background: url("/img/checkboxmark.svg") no-repeat 0 0;
  background-size: cover;
  display: inline-block;
  height: 11px;
  margin-right: 6px;
  width: 16px;
}

.button {
  background: #6ab131;
  border: 1px solid transparent;
  border-radius: 3px;
  box-shadow: none;
  color: #fff;
  cursor: pointer;
  /* Improves usability and consistency of cursor style between image-type 'input' and others */
  display: inline-block;
  font-size: inherit;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  height: 48px;
  line-height: 48px;
  text-transform: uppercase;
  text-decoration: none;
  padding: 0 30px;
  position: relative;
  outline: none;
  text-align: center;
  width: auto;
}

.button:hover, .button:focus {
  color: #fff;
  border-color: transparent;
  background: #528926;
  text-decoration: none;
}

.button--sm {
  font-size: 0.875rem;
  height: 34px;
  line-height: 34px;
  padding: 0 20px;
}

.button.loading {
  cursor: wait;
  pointer-events: none;
  opacity: .75;
  transition: all .5s;
}

.button--secondary {
  background: none;
  border-color: currentColor;
  color: #6ab131;
}

.button--secondary:hover, .button--secondary:focus {
  background: none;
  border-color: currentColor;
  color: #528926;
}

.button--facebook {
  background: #4066b1;
  border-color: #4066b1;
}

.button--facebook:hover, .button--facebook:focus {
  background: #395b9e;
  border-color: #395b9e;
}

.button--google {
  background: #ea4335;
  border-color: #ea4335;
}

.button--google:hover, .button--google:focus {
  background: #e82e1e;
  border-color: #e82e1e;
}

.button--icon::before {
  content: '';
  background-position: center;
  background-repeat: no-repeat;
  height: 23px;
  width: 38px;
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.button--facebook::before {
  background-image: url("/img/facebook.svg");
  background-size: contain;
  background-position: 6px center;
}

.button--google::before {
  background-image: url("/img/google-plus.svg");
}

.button[disabled], .button.is-loading {
  pointer-events: none;
  opacity: .7;
}

.autocomplete {
  padding-left: 0;
  list-style: none;
  background: #fff;
  border: 1px solid #ecedee;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
  border-radius: 0;
  z-index: 9999;
  position: absolute !important;
  max-height: 300px;
  overflow-y: auto;
}

.autocomplete a {
  background: none;
  cursor: pointer;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  display: flex;
  overflow: hidden;
  padding: 6px 10px;
  text-decoration: none;
}

.autocomplete a br {
  display: none;
}

.autocomplete a span:first-child {
  flex: 50px;
  max-width: 50px;
  overflow: hidden;
}

.autocomplete a img {
  display: block;
  margin: 0 auto;
  max-height: 50px;
}

.autocomplete a span:last-of-type {
  padding-left: 10px;
  flex: 1;
}

.autocomplete a:hover, .autocomplete a.ui-state-active {
  background: #e7f5dc;
}

.ui-helper-hidden-accessible {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.autocomplete--loading {
  display: none;
}

.autocomplete--loading {
  position: absolute;
  left: 0;
  right: 0;
}

.autocomplete--loading.is-loading {
  display: block;
}

.autocomplete--loading::before {
  content: "";
  display: none;
  background-color: #fff;
  background-image: linear-gradient(#eaeaea 50px, transparent 0), linear-gradient(100deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 80%), linear-gradient(#eaeaea 20px, transparent 0), linear-gradient(#eaeaea 20px, transparent 0);
  background-repeat: repeat-y;
  background-size: 50px 60px, 50px 60px, 150px 60px, 250px 60px;
  background-position: 0 0, 0 0, 60px 0, 60px 30px;
  position: absolute;
  left: 10px;
  right: 10px;
  bottom: 10px;
  top: 10px;
  animation: shine 1s infinite;
}

.autocomplete--loading.is-loading {
  min-height: 200px;
  overflow-y: hidden;
  max-height: 300px;
}

.autocomplete--loading.is-loading li {
  visibility: hidden;
}

.autocomplete--loading.is-loading::before {
  display: block;
}

.autocomplete {
  position: relative;
}

.autocomplete::before {
  content: "";
  display: none;
  background-color: #fff;
  background-image: linear-gradient(#eaeaea 50px, transparent 0), linear-gradient(100deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 80%), linear-gradient(#eaeaea 20px, transparent 0), linear-gradient(#eaeaea 20px, transparent 0);
  background-repeat: repeat-y;
  background-size: 50px 60px, 50px 60px, 150px 60px, 250px 60px;
  background-position: 0 0, 0 0, 60px 0, 60px 30px;
  position: absolute;
  left: 10px;
  right: 10px;
  bottom: 10px;
  top: 10px;
  animation: shine 1s infinite;
}

.autocomplete.is-loading {
  min-height: 200px;
  overflow-y: hidden;
  max-height: 300px;
}

.autocomplete.is-loading li {
  visibility: hidden;
}

.autocomplete.is-loading::before {
  display: block;
}

@keyframes shine {
  to {
    background-position: 0 0, 100% 0, 60px 0, 60px 30px;
  }
}

/* search */
.search {
  margin: 0;
  position: relative;
  width: 100%;
}

.search input[type="search"] {
  font-size: 1.125rem;
  height: 45px;
  padding-right: 50px;
  max-width: none;
  width: 100%;
}

.search button {
  background: none;
  border: none;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 45px;
  text-align: center;
  padding: 0;
}

.search button svg {
  fill: currentColor;
  height: 20px;
  width: auto;
}

.search button:hover {
  background: none;
}

.search__close {
  display: none;
}

#search-fake-button.is-active {
  color: #fff;
}

#search-wrapper {
  background: #000;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  top: 100%;
  display: none;
}

#search-wrapper .wrapper:after {
  content: "";
  background: #000;
  position: absolute;
  top: -81px;
  right: 0;
  width: 46px;
  height: 81px;
  right: 2px;
}

@media (max-width: 864px) {
  #search-wrapper .wrapper:after {
    top: auto;
    bottom: 100%;
    right: 79px;
    height: 52px;
    width: 38px;
  }
}

#search-wrapper.in-focus {
  z-index: 1;
}

#search-wrapper form {
  position: relative;
  display: block !important;
  max-width: 610px;
  margin: 30px auto;
}

@media (max-width: 864px) {
  #search-wrapper form {
    margin-top: 14px;
    margin-bottom: 14px;
  }
}

#search-wrapper form input[type="search"] {
  background: #b3b3b3;
  color: #010101;
  border: none;
  display: block;
  font-size: 24px;
  height: 60px;
  padding: 0 9px;
  width: 100%;
  max-width: none;
  text-align: center;
  position: relative;
  z-index: -1;
}

@media (max-width: 864px) {
  #search-wrapper form input[type="search"] {
    font-size: 18px;
    height: 35px;
  }
}

#search-wrapper form input[type="search"]::placeholder {
  color: #010101;
  opacity: 1;
}

#search-wrapper form input[type="search"].is-visible {
  width: 100%;
  z-index: 1;
}

#search-wrapper form *[type="submit"] {
  background: none;
  border: none;
  color: #000;
  cursor: pointer;
  font-size: 16px;
  padding: 0 9px;
  margin: 0;
  height: 33px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  visibility: hidden;
}

#search-wrapper form *[type="submit"].is-active {
  color: #fff;
}

#search-wrapper form *[type="submit"].is-visible {
  visibility: visible;
}

.search {
  margin: 0;
  flex: 1;
}

.search input[type="search"] {
  font-size: 1rem;
  padding-left: 48px;
  padding-right: 18px;
  border-color: #c4c4c4;
}

.search button {
  left: 0;
  right: auto;
}

.search svg {
  color: #cdcfd1;
  height: 21px;
  width: 20px;
}

@media (min-width: 1024px) {
  .search {
    width: 250px;
  }
}

@media (min-width: 1366px) {
  .search {
    width: 350px;
  }
}

.newsletter input[type="email"] {
  flex: 1;
}

.newsletter.disabled {
  pointer-events: none;
  opacity: .85;
}

.popup {
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}

.popup[aria-hidden="true"] {
  display: none;
}

.popup__close {
  background: none;
  position: absolute;
  top: 10px;
  right: 10px;
  height: 18px;
  width: 18px;
}

.popup__close:hover {
  background: none;
}

.popup__close svg {
  display: block;
}

.popup__content {
  border: 1px solid #ecedee;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
  background: #fff;
  padding: 40px;
  width: 90%;
  max-width: 576px;
  max-height: 95%;
  overflow-y: auto;
  position: relative;
}

.popup__title {
  display: block;
  font-size: 30px;
  margin: 0 0 24px 0;
}

@media (max-width: 767px) {
  .popup__content {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.age .popup__logo {
  display: block;
  margin: 0 auto 30px;
}

.age .popup__title {
  font-size: 1.5rem;
  border-top: 1px solid #ecedee;
  padding-top: 30px;
  text-align: center;
}

.age .popup__buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 27px;
}

.age .button {
  width: calc(50% - 5px);
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 767px) {
  .age {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.dropdown {
  position: relative;
  z-index: 3;
}

.dropdown-trigger {
  text-decoration: none;
}

.dropdown-arrow {
  background: url("/img/select_arrow.svg") no-repeat right center;
  padding-right: 20px;
  background-size: auto 6px;
  position: relative;
  z-index: 2;
  display: inline-block;
  position: relative;
}

.dropdown-arrow--disabled {
  background: none;
  padding-right: 0;
}

.dropdown-container {
  display: none;
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translateX(-50%);
  padding-top: 9px;
}

.dropdown-container::before {
  content: "";
  background: url("/img/dropdown_arrow.svg") no-repeat 0 0;
  background-size: cover;
  filter: drop-shadow(0px -3px 1px rgba(0, 0, 0, 0.15));
  height: 9px;
  width: 15px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.dropdown-container--right {
  right: 0;
  left: auto;
  transform: none;
}

.dropdown-container--right::before {
  left: auto;
  right: 10px;
  transform: none;
}

.dropdown-container--left {
  right: auto;
  left: 0;
  transform: none;
}

.dropdown-container--left::before {
  left: 10px;
  transform: none;
}

.dropdown-list {
  padding-left: 0;
  list-style: none;
  background: #fff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
  border-radius: 0;
  margin: 0;
  padding: 15px 0;
  position: relative;
}

.dropdown-list li {
  margin-top: 0;
}

.dropdown-list li > a,
.dropdown-list li > span {
  background: none;
  color: inherit;
  cursor: pointer;
  display: block;
  padding: 0.5rem 20px;
  text-decoration: none;
  white-space: nowrap;
}

.dropdown-list li > a:hover,
.dropdown-list li > span:hover {
  color: inherit;
  background: #fcfcfc;
}

.dropdown br {
  display: none;
}

.dropdown:hover .dropdown-arrow::before {
  display: block;
}

.dropdown:hover .dropdown-container {
  display: block;
}

.card {
  background: transparent;
  color: inherit;
  display: flex;
  flex-direction: column;
  border: 0 solid none;
  border-radius: 0;
  box-shadow: none;
  position: relative;
  overflow: hidden;
}

.card-header {
  background: transparent;
  color: inherit;
  display: flex;
  border-bottom: 0 solid none;
  font-size: 24px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  line-height: 1.3;
  padding: 0 0;
  margin-top: 0;
  margin-bottom: 1rem;
  position: relative;
  text-transform: uppercase;
}

.card-header a {
  align-self: center;
  color: inherit;
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  margin-left: auto;
  text-transform: none;
}

.card-header a:hover {
  color: #528926;
}

.card-body {
  flex: 1 0 auto;
  padding: 0 0;
}

.card-body > *:first-child {
  margin-top: 0;
}

.card-body > *:last-child {
  margin-bottom: 0;
}

.card-footer {
  border-top: 0 solid none;
  padding: 0 0;
}

.card + .card {
  margin-top: 45px;
}

.breadcrumb {
  font-size: 1rem;
  position: relative;
  margin-bottom: 45px;
}

@media (max-width: 767px) {
  .breadcrumb {
    margin-bottom: 1rem;
  }
}

.breadcrumb ol {
  border-bottom: 1px solid #ecedee;
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0.75rem 0;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (max-width: 1440px) {
  .breadcrumb ol {
    border-top: 1px solid #ecedee;
  }
}

.breadcrumb li {
  color: #999999;
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  margin: 0;
  position: relative;
  text-transform: uppercase;
  margin-right: 9px;
}

.breadcrumb li:not(:last-child):after {
  content: "/";
  margin-left: 9px;
}

.breadcrumb li:first-of-type {
  padding-left: 0;
}

.breadcrumb li:first-of-type:first-of-type:before {
  display: none;
}

.breadcrumb li:last-child {
  color: inherit;
  font-size: inherit;
}

.breadcrumb li:last-child a {
  pointer-events: none;
}

.breadcrumb a {
  color: inherit;
  text-decoration: none;
}

.breadcrumb a:hover {
  color: #528926;
  text-decoration: none;
}

@media (max-width: 767px) {
  .breadcrumb li:not(:first-child):not(:last-child) {
    display: none;
  }
}

.breadcrumb a {
  background: none;
}

.modal {
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  height: 100%;
  overflow: auto;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
}

.modal__content {
  max-height: 90%;
  width: 100%;
  max-width: 900px;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 864px) {
  .modal__content {
    max-width: 90%;
  }
}

.modal__close {
  color: inherit;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 14;
  background: none !important;
  cursor: pointer;
}

.modal__content-wrapper {
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  padding: 30px;
  position: relative;
  overflow: hidden;
  overflow-y: auto;
}

@media (max-width: 864px) {
  .modal__content-wrapper {
    padding: 15px;
  }
}

.modal__header {
  margin-bottom: 9px;
  margin-top: 0;
}

.modal .grecaptcha-badge {
  position: absolute !important;
  right: -186px !important;
}

.modal .buttons-container {
  text-align: center;
}

.modal--secondary .modal__content {
  max-width: 500px;
}

.text-screen-reader {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.message--us-fda-approval {
  font-weight: 600;
  margin-bottom: 0;
  text-align: center;
  padding: 12px 9px;
  padding-top: 15px;
  background: #303130;
  color: #6AB131;
  font-size: 14px;
  line-height: 16px;
  height: auto;
  box-sizing: border-box;
  position: absolute;
  z-index: 300;
  display: flex;
  justify-content: center;
  width: 100%;
  bottom: 0;
  right: 0;
}

.message--us-fda-approval > span {
  margin-right: 10px;
}
