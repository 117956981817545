.card {
    background: $card-bg;
    color: $card-color;
    display: flex;
    flex-direction: column;
    border: $card-border-width $card-border-style $card-border-color;
    border-radius: $card-border-radius;
    box-shadow: $card-box-shadow;
    position: relative;
    overflow: hidden;

    &-header {
        background: $card-header-bg;
        color: $card-header-color;
        display: flex;
        // check border
        border-bottom: $card-header-border-bottom-width $card-border-style $card-border-color;

        // border-radius: $card-border-radius;
        font-size: $card-header-font-size;
        font-family: $card-header-font-family;
        font-weight: $card-header-font-weight;
        line-height: $card-header-line-height;

        // check space
        padding: $card-header-footer-padding-y $card-padding-x;
        margin-top: 0;
        margin-bottom: rem($card-header-margin-bottom);

        position: relative;
        text-transform: $card-header-text-transform;

        a {
            align-self: center;
            color: inherit;
            font-size: $font-size-base;
            font-family: $font-family-base;
            font-weight: $font-weight-normal;
            margin-left: auto;
            text-transform: none;

            &:hover {
                color: $link-color-hover;
            }
        }
    }

    &-body {
        flex: 1 0 auto;
        padding: $card-padding-y $card-padding-x;

        & > *:first-child {
            margin-top: 0;
        }

        & > *:last-child {
            margin-bottom: 0;
        }
    }

    &-footer {
        border-top: $card-border-width $card-border-style $card-border-color;
        padding: $card-header-footer-padding-y $card-padding-x;
    }

    // ---
    // spaces
    & + .card {
        margin-top: $card-spacing-y-top;
    }
}

// ---
// theme overwrites
@if (variable-exists(cards-overwrite)) {
    @if ($cards-overwrite) {
        @include cards;
    }
}
