// ---
// styles
.help-block {
    display: block;
    font-size: $font-size-sm;
    line-height: $line-height-sm;
    margin-top: 3px;

    & + & {
        margin-top: 0;
    }
}

.help-block.form-error,
.help-block.form-error--password {
    color: $input-required-color;
}

.help-block.form-success {
    color: $input-success-color;
}

.help-inline {
    display: inline-block;
    margin-right: $elements-spacing;
}

.error {
    border-bottom-color: $input-required-color;
}
