// ---
// groups
.form-group {
	margin: 0;

	& + .form-group {
		margin-top: $form-group-margin-top;
	}
}

// ---
// labels
label {
	display: block;
	margin: 0 0 $label-margin-bottom;
	width: auto;
}

// ---
// inputs
select,
textarea,
input {
	background: $input-bg;
	color: $input-color;
	display: block;
	font-size: $input-font-size;
	font-family: $input-font-family;

	@if ($input-enable-border-bottom-only) {
		border: none;
		border-bottom: $input-border-width $input-border-style $input-border-color;
	} @else {
		border: $input-border-width $input-border-style $input-border-color;
	}

	border-radius: $input-border-radius;
	height: $input-height;
	margin: 0;

	@if ($input-enable-border-bottom-only) {
		padding-left: 0;
		padding-right: 0;
	} @else {
		padding-left: $input-padding-x;
		padding-right: $input-padding-x;
	}

	width: $input-width;
	max-width: $input-width-max;
	min-width: 1px;

	&.sm {
		font-size: $input-font-size-sm;
		height: $input-height-sm;
		padding-left: $input-padding-x-sm;
		padding-right: $input-padding-x-sm;
	}
}

textarea {
	height: $input-textarea-height;
	max-width: none;
}

*[disabled] {
	background: $input-disabled-bg;
}

// ---
// select
select,
option {
	-webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;
	appearance: none;
}

select {
	background-image: url(#{$input-select-bg-image});
	background-repeat: no-repeat;
	background-position: right 10px center;
	background-size: $input-select-bg-size-width $input-select-bg-size-height;
	padding-right: 36px;
}

// removes internet explorer arrow
select::-ms-expand {
	display: none;
}

// ---
// float label
.form-group--float-label {
	position: relative;

	label {
		position: absolute;
		top: 0;
		font-size: rem(12);
		line-height: 1;
		font-weight: $font-weight-bold;
		left: 0;
		opacity: 0;
		transition: all 0.5s;
		z-index: 1;

		+ input,
		+ select,
		+ textarea {
			transition: all 0.5s;
		}
	}

	&.show {
		label {
			opacity: 1;
		}

		input,
		select,
		textarea {
			padding-top: 10px;
		}
	}

	// show
	&[class*='col-'] {
		label {
			left: $grid-gutter-width / 2;
		}
	}

	select {
		background-position: right bottom 14px;
	}
}

.form-group--float-label.form-group--select:not(.show) {
	label {
		color: $input-placeholder-color;
		opacity: 1;
		font-size: inherit;
		font-weight: inherit;
		top: 13px; //
		// transform: translateY(-50%);
	}
}

// ---
// inline field and button
.form-group__inline {
	display: flex;
}

// ---
// radios and checkboxes
input[type='radio'],
input[type='checkbox'] {
	height: auto;
	width: auto;
	margin: 0 6px 0 0;
}

.label-info {
	display: flex;
	flex-wrap: wrap;
	align-items: center;

	> *:not(:last-child) {
		margin-right: 9px;
	}
}

// ---
// tooltips in form group
.form-group {
    .tooltip-wrapper {
        [data-toggle="tooltip"] {
            top: $input-height / 2;
            margin-top: -($tooltip-height / 2);
            transform: none;
        }
    }
}

// ---
// radio/checkbox
@import '../components/custom-radio-checkbox';

// ---
// tooltip
@import '../components/tooltip';
