/* latin */
@import 'fonts';

//
html {
	&.no-overflow {
		overflow: hidden;
	}

	font-family: $font-family-base;
	font-size: $font-size-sm;
	line-height: $line-height;
	font-weight: $font-weight-normal;

	@media (min-width: map-get($container-max-widths, md)) {
		font-size: $font-size-base;
		line-height: $line-height;
	}
}

// body
body {
	background: $body-bg;
	overflow-x: hidden;
	color: $body-color;

	// ---
	// sticky footer
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

// ---
// links
@import 'links';

// ---
// headings
@import 'headings';

// ---
// lists
ol,
ul {
	padding-left: 1rem;
}

// ---
// spaces
ul,
ol,
dl,
p {
	margin: 0 0 rem(9) 0;
}

li {
	margin: 0;
}

li + li {
	margin-top: rem(1.5);
}

table {
	margin: rem(15) 0;
}

h2,
h3,
h4,
h5,
h6 {
	margin-bottom: rem($headings-margin-bottom);
}

h2 {
	margin-top: rem($headings-margin-top);
}

h3,
h4,
h5,
h6 {
	margin-top: rem(24);
}

h2 + h3 {
	margin-top: 0;
}

.sr-only {
	@include sr-only;
}

// ---
// text
@import 'eztext';
