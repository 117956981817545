// HTML example
//
// - Checkbox
// <div class="nmx-radio custom-control custom-checkbox">
//   <input name="checkboxname" id="checkboxid" type="checkbox">
//   <label class="custom-control-label" for="checkboxid">Checkbox Label</label>
// </div>
//
// - Radio
// <div class="nmx-radio custom-control custom-radio">
//   <input name="radioname" id="radioid" type="radio">
//   <label class="custom-control-label" for="radioid">Radio Label</label>
// </div>

// Checkboxes and radios
//
// Base class takes care of all the key behavioral aspects.

.custom-control {
    position: relative;
    display: block;
    padding-left: $custom-control-gutter;
}

.custom-control-inline {
    display: inline-flex;
    margin-right: $custom-control-spacer-x;
}

.custom-checkbox,
.custom-radio {
    input {
        position: absolute;
        z-index: -1; // Put the input behind the label so it doesn't overlay text
        opacity: 0;

        &:checked ~ label::before {
            color: $custom-control-indicator-checked-color;
            background-color: $custom-control-indicator-checked-bg;
            // @include box-shadow($custom-control-indicator-checked-box-shadow);
        }

        &:focus ~ label::before {
            // the mixin is not used here to make sure there is feedback
            box-shadow: $custom-control-indicator-focus-box-shadow;
        }

        &:active ~ label::before {
            color: $custom-control-indicator-active-color;
            background-color: $custom-control-indicator-active-bg;
            // @include box-shadow($custom-control-indicator-active-box-shadow);
        }

        &:disabled {
            ~ label {
                color: $custom-control-label-disabled-color;

                &::before {
                    background-color: $custom-control-indicator-disabled-bg;
                }
            }
        }
    }
}

// Custom control indicators
//
// Build the custom controls out of pseudo-elements.

.custom-checkbox,
.custom-radio {
    label {
        position: relative;
        margin-bottom: 0;
        line-height: inherit;

        // Background-color and (when enabled) gradient
        &::before {
            position: absolute;
            top: $custom-control-top;
            left: -$custom-control-gutter;
            display: block;
            border: $custom-control-indicator-border-width solid
                $custom-control-indicator-border-color;
            width: $custom-control-indicator-size;
            height: $custom-control-indicator-size;
            pointer-events: none;
            content: "";
            user-select: none;
            background-color: $custom-control-indicator-bg;
            // @include box-shadow($custom-control-indicator-box-shadow);
        }

        // Foreground (icon)
        &::after {
            position: absolute;
            top: $custom-control-top;
            left: -$custom-control-gutter;
            display: block;
            width: $custom-control-indicator-size;
            height: $custom-control-indicator-size;
            content: "";
            background-repeat: no-repeat;
            background-position: center center;
            background-size: $custom-control-indicator-bg-size;
        }
    }
}

// Checkboxes
//
// Tweak just a few things for checkboxes.

.custom-checkbox {
    label::before {
        border-radius: $custom-checkbox-indicator-border-radius;
    }

    input:checked ~ label {
        &::before {
            background-color: $custom-control-indicator-checked-bg;
        }
        &::after {
            background-image: $custom-checkbox-indicator-icon-checked;
        }
    }

    &.active > label {
        &::before {
            background-color: $custom-control-indicator-checked-bg;
        }
        &::after {
            background-image: $custom-checkbox-indicator-icon-checked;
        }
    }

    input:indeterminate ~ label {
        &::before {
            // @include gradient-bg($custom-checkbox-indicator-indeterminate-bg);
            // @include box-shadow($custom-checkbox-indicator-indeterminate-box-shadow);
        }
        &::after {
            background-image: $custom-checkbox-indicator-icon-indeterminate;
        }
    }

    input:disabled {
        &:checked ~ label::before {
            background-color: $custom-control-indicator-checked-disabled-bg;
        }
        &:indeterminate ~ label::before {
            background-color: $custom-control-indicator-checked-disabled-bg;
        }
    }
}

// Radios
//
// Tweak just a few things for radios.

.custom-radio {
    label::before {
        border-radius: $custom-radio-indicator-border-radius;
    }

    input:checked ~ label {
        &::before {
            background-color: $custom-control-indicator-checked-bg;
        }
        &::after {
            background-image: $custom-radio-indicator-icon-checked;
        }
    }

    input:disabled {
        &:checked ~ label::before {
            background-color: $custom-control-indicator-checked-disabled-bg;
        }
    }
}

// ---
// theme overwrites
@if (variable-exists(custom-radio-checkbox-overwrite)) {
    @if ($custom-radio-checkbox-overwrite) {
        @include custom-radio-checkbox;
    }
}
