// ---
// colors
$black: #000 !default;
$gray: #ccc !default;
$white: #fff !default;

// ---
// font
$font-family-base: "Roboto", sans-serif !default;
$font-family-bold: "Roboto Condensed", sans-serif !default;

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg: ($font-size-base * 1.375) !default;
$font-size-md: ($font-size-base * 1.125) !default;
$font-size-sm: ($font-size-base * 0.875) !default;
$font-size-xs: 12px !default;
$font-size-min: 10px; // smallest it can be
$font-size-base-px: 16px;

$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-semi-bold: 600 !default;
$font-weight-bold: 700 !default;

// ---
// line heights
$line-height-base: 10px !default;
$line-height-base-sm: 6px !default;
$line-height: calc(#{$font-size-base} + #{$line-height-base}) !default;
$line-height-sm: calc(#{$font-size-sm} + #{$line-height-base-sm}) !default;
$line-height-lg: calc(#{$font-size-lg} + #{$line-height-base}) !default;

// ---
// Body
$body-bg: $white !default;
$body-color: $black !default;

// ---
// Links
$enable-link-bg-underline: true !default;

$link-bg: transparent !default;
$link-bg-hover: $black !default;
$link-color: $black !default;
$link-color-hover: $white !default;
$link-decoration: none !default;
$link-decoration-hover: underline !default;

// ---
// headings
$heading-line-height: $line-height-base-sm !default;
$heading-text-transform: uppercase !default;

$h1-font-size: 30px !default;
$h2-font-size: $h1-font-size - 6px !default;
$h3-font-size: $h2-font-size - 6px !default;
$h4-font-size: $h3-font-size - 6px !default;
$h5-font-size: $h4-font-size !default;
$h6-font-size: $h4-font-size !default;

$headings-margin-bottom: 10px !default;
$headings-margin-top: 30px !default;
$headings-font-family: $font-family-bold !default;
$headings-font-weight: $font-weight-normal !default;
$headings-line-height: 1.2 !default;
$headings-color: inherit !default;

// ---
// elements spacing
$elements-spacing: 10px !default;
$elements-spacing-lg: 30px !default;

// ---
// components
$component-bg: #fff !default;
$component-header-bg: none !default;
$component-border-color: $gray !default;
$component-border-style: solid !default;
$component-border-width: 1px !default;
$component-box-shadow-color: rgba(0, 0, 0, 0.15) !default;
$component-box-shadow: 0 0 6px 0 $component-box-shadow-color !default;
$component-border-radius: 0 !default;
$component-overlay-bg: rgba(0, 0, 0, 0.5);

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
    xxxs: 0,
    xxs: 374px,
    xs: 415px,
    sm: 576px,
    md: 768px,
    mdup: 769px,
    lg: 1024px,
    xl: 1280px,
    xxl: 1440px,
    xxxl: 1680px,
) !default;

// Grid containers

// Define the maximum width of `.container` for different screen sizes.
// sm, md, lg probably not used because we control the space with margin-left and margin-right set to main wrapper
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1232px,
    xxl: 1560px,
) !default;
// Grid columns

// Set the number of columns and specify the width of the gutters.
$grid-columns: 12 !default;
$grid-gutter-width: 24px !default;

// ---
// Forms
$input-enable-border-bottom-only: true !default;

$input-success-color: green !default;
$input-required-color: #d82f2e !default;
$label-margin-bottom: 0.25rem !default;

$input-height: 44px !default;
$input-padding-y: 0 !default;
$input-padding-x: 0.555rem !default;
$input-padding-y-bottom: 0 !default;
$input-line-height: $line-height !default;

$input-height-sm: 34px !default;
$input-padding-y-sm: 0 !default;
$input-padding-x-sm: 0.255rem !default;

$input-bg: $white !default;
$input-disabled-bg: $gray !default;

$input-color: inherit !default;
$input-border-color: $component-border-color !default;
$input-border-width: $component-border-width !default;
$input-border-style: $component-border-style !default;
$input-box-shadow: none !default;

$input-font-size: inherit !default;
$input-font-size-sm: $font-size-sm !default;
$input-font-family: inherit !default;

$input-border-radius: 0 !default;

$input-focus-bg: $input-bg !default;
$input-focus-border-color: inherit !default;
$input-focus-color: inherit !default;
$input-focus-width: inherit !default;
$input-focus-box-shadow: none !default;

$input-placeholder-color: lighten($body-color, 50%) !default;
$input-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !default;

$input-width: 100% !default;
$input-width-max: 290px !default;

$input-select-bg-image: "/img/select_arrow.svg" !default;
$input-select-bg-size-height: 6px !default;
$input-select-bg-size-width: 10px !default;

$form-text-margin-top: 0.25rem !default;

$form-check-input-gutter: 1.25rem !default;
$form-check-input-margin-y: 0.3rem !default;
$form-check-input-margin-x: 0.25rem !default;

$form-check-inline-margin-x: 0.75rem !default;
$form-check-inline-input-margin-x: 0.3125rem !default;

$form-group-margin-top: 1rem !default;

$input-group-addon-color: none !default;
$input-group-addon-bg: none !default;
$input-group-addon-border-color: none !default;

$input-textarea-height: 100px !default;

$custom-forms-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !default;

$custom-control-gutter: 1.5rem !default;
$custom-control-spacer-x: 1rem !default;
$custom-control-top: 5px !default;

$custom-control-indicator-size: 1rem !default;
$custom-control-indicator-bg: $input-bg !default;
$custom-control-indicator-bg-size: 80% !default;
$custom-control-indicator-box-shadow: none !default;

$custom-control-indicator-border-color: $input-border-color !default;
$custom-control-indicator-border-width: $component-border-width !default;

$custom-control-indicator-disabled-bg: $gray !default;
$custom-control-label-disabled-color: $gray !default;

$custom-control-indicator-checked-color: none !default;
$custom-control-indicator-checked-bg: #fff !default;
$custom-control-indicator-checked-disabled-bg: rgba($white, 0.5) !default;
$custom-control-indicator-checked-box-shadow: none !default;

$custom-control-indicator-focus-box-shadow: none !default;

$custom-control-indicator-active-color: none !default;
$custom-control-indicator-active-bg: #fff !default;
$custom-control-indicator-active-box-shadow: none !default;

$custom-checkbox-indicator-border-radius: 0 !default;
$custom-checkbox-indicator-icon-checked: url(/img/checkboxmark.svg) !default;

$custom-checkbox-indicator-indeterminate-bg: none !default;
$custom-checkbox-indicator-indeterminate-color: none !default;
$custom-checkbox-indicator-icon-indeterminate: none !default;
$custom-checkbox-indicator-indeterminate-box-shadow: none !default;

$custom-radio-indicator-border-radius: 50% !default;
$custom-radio-indicator-icon-checked: url(/img/radiomark.svg) !default;

// ---
// messages
$message-padding-y: 9px !default;
$message-padding-x: 9px !default;
$message-bg: transparent !default;
$message-color: inherit !default;
$message-border-width: 1px !default;
$message-border-style: solid !default;
$message-border-color: transparent !default;
$message-border-radius: 0 !default;
$message-success-bg: #dff0d8 !default;
$message-success-border-color: #d6e9c6 !default;
$message-success-color: #3c763d !default;
$message-error-bg: #f2dede !default;
$message-error-border-color: #ebccd1 !default;
$message-error-color: #a94442 !default;
$message-attention-bg: #d1ecf1 !default;
$message-attention-border-color: #bee5eb !default;
$message-attention-color: #0c5460 !default;

// ---
// breakpoints
$bp-site-xs: 330px !default;
$bp-site-mp: 560px !default;
$bp-site-ml: 767px !default;
$bp-site-tp: 864px !default;
$bp-site-tl: 1000px !default;
$bp-site-de: 1232px !default;
$bp-site-fd: 1836px !default;
$m-site: 15px !default;

// ---
// gallery
$w-gallery: 576px !default;
$w-gallery-mb: 480px !default;

// ---
// products
$product-font-size: 16px !default;
$product-line-height: $line-height-base-sm;

$product-title-spacing-y: 9px !default;
$product-title-color: inherit !default;
$product-title-color-hover: $link-color-hover !default;
$product-title-font-size: inherit !default;
$product-title-font-weight: $font-weight-bold !default;

$product-listing-price-spacing-y: 3px !default;
$product-price-color: inherit !default;
$product-price-font-size: $product-font-size !default;
$product-price-font-weight: inherit !default;
$product-price-normal-color: inherit !default;
$product-price-normal-font-weight: $font-weight-normal !default;
$product-price-normal-text-decoration: line-through !default;
$product-price-special-color: #b1109c !default;
$product-price-special-font-weight: $font-weight-normal !default;
$product-price-off-color: #b1109c !default;
$product-model-font-size: 16px !default;

// ---
// breadcrumb
$breadcrumb-border-width: $component-border-width !default;
$breadcrumb-border-style: $component-border-style !default;
$breadcrumb-border-color: $component-border-color !default;
$breadcrumb-separator: "/" !default;
$breadcrumb-spacing-y: 1.875rem !default;
$breadcrumb-color: #999999 !default;
$breadcrumb-color-hover: #999999 !default;
$breadcrumb-color-active: inherit !default;
$breadcrumb-font-size: $font-size-base !default;
$breadcrumb-font-size-active: 36px !default;
$breadcrumb-font-weight: $font-family-bold !default;
$breadcrumb-text-transform: uppercase !default;
$breadcrumb-line-height-active: $heading-line-height !default;

$breadcrumb-link-color: inherit !default;
$breadcrumb-link-color-hover: $link-color-hover !default;
$breadcrumb-link-text-decoration: none !default;
$breadcrumb-link-text-decoration-hover: none !default;

$breadcrumb-list-padding-y: 0.75rem !default;
$breadcrumb-list-padding-x: 0 !default;
$breadcrumb-list-item-spacing-x: 9px !default;

// ---
// dropdown
$dropdown-container-enable-arrow-top: true !default;

$dropdown-trigger-image: "/img/select_arrow.svg" !default;
$dropdown-trigger-padding-x-right: 15px !default;
$dropdown-trigger-image-height: 6px !default;

$dropdown-container-arrow-img: "/img/dropdown_arrow.svg" !default;
$dropdown-container-arrow-box-shadow: 0px -3px 1px $component-box-shadow-color !default;
$dropdown-container-arrow-height: 9px !default;
$dropdown-container-arrow-width: 15px !default;
$dropdown-container-arrow-position-left: 10px !default;
$dropdown-container-arrow-position-right: 10px !default;

$dropdown-container-padding-top: $dropdown-container-arrow-height !default; // should be same as arrow height

$dropdown-list-bg: $white !default;
$dropdown-list-box-shadow: $component-box-shadow !default;
$dropdown-list-border-radius: $component-border-radius !default;

$dropdown-list-padding: 15px 0 !default;
$dropdown-list-item-bg: none !default;
$dropdown-list-item-bg-hover: #fcfcfc !default;
$dropdown-list-item-color: inherit !default;
$dropdown-list-item-color-hover: inherit !default;
$dropdown-list-item-padding: 0.5rem 20px !default;

// ---
// buttons
$button-bg: #000 !default;
$button-bg-hover: #333 !default;
$button-color: $white !default;
$button-color-hover: $button-color !default;
$button-border-width: 1px !default;
$button-border-style: solid !default;
$button-border-color: transparent !default;
$button-border-color-hover: $button-border-color !default;

// button secondary invert main button colors
$button-secondary-bg: none !default;
$button-secondary-bg-hover: none !default;
$button-secondary-color: $button-bg !default;
$button-secondary-color-hover: $button-bg-hover !default;
$button-secondary-border-color: currentColor !default;
$button-secondary-border-color-hover: currentColor !default;

// button social
$button-facebook-bg: #4066b1 !default;
$button-facebook-bg-hover: darken($button-facebook-bg, 5%) !default;
$button-google-bg: #ea4335 !default;
$button-google-bg-hover: darken($button-google-bg, 5%) !default;

$button-border-radius: 0.222em !default;
$button-box-shadow: none !default;
$button-padding-y: 0 !default; // usually header y-padding is smaller than body
$button-padding-y-small: 0 !default; // usually header y-padding is smaller than body
$button-padding-x: 30px !default; // usually header x-padding is the same as body
$button-padding-x-small: 20px !default; // usually header x-padding is the same as body
$button-font-size: inherit !default;
$button-font-family: $font-family-bold !default;
$button-font-weight: inherit !default;
$button-text-transform: uppercase !default;
$button-height: 44px !default;
$button-line-height: $button-height !default;
$button-font-size-small: $font-size-sm !default;
$button-height-small: 34px !default;
$button-line-height-small: $button-height-small !default;

$button-indicator-icon-checked-height: 11px !default;
$button-indicator-icon-checked-width: 16px !default;
$button-indicator-icon-checked: url("/img/checkboxmark.svg") !default;

// ---
// cards
// $card-space:							true !default;
$card-spacing-y-top: $elements-spacing-lg + ($elements-spacing-lg / 2) !default;
$card-padding-y: 30px !default;
$card-padding-x: 27px !default;
$card-header-footer-padding-y: 12px !default; // usually header y-padding is smaller than body
$card-bg: transparent !default;
$card-color: inherit !default;
$card-border-width: 0 !default;
$card-border-style: $component-border-style !default;
$card-border-color: $component-border-color !default;
$card-border-radius: 4px !default;
$card-box-shadow: none !default;
$card-header-bg: $gray !default;
$card-header-border-bottom-width: $card-border-width !default;
$card-header-color: $black !default;
$card-header-font-size: inherit !default;
$card-header-font-family: $font-family-bold !default;
$card-header-font-weight: inherit !default;
$card-header-line-height: 1.3 !default;
$card-header-text-transform: uppercase !default;
$card-header-margin-bottom: 0 !default;

// ---
// pagination
$pagination-padding-y: 0 !default;
$pagination-padding-x: 0 !default;
$pagination-bg: none !default;
$pagination-color: inherit !default;
$pagination-link-border-width: 0 !default;
$pagination-link-bg: none !default;
$pagination-link-color: inherit !default;
$pagination-link-padding-y: 0 !default;
$pagination-link-padding-x: 0 !default;
$pagination-link-border-width: 0 !default;
$pagination-link-border-style: solid !default;
$pagination-link-border-color: none !default;
$pagination-link-border-radius: 0 !default;

// hover
$pagination-hover-link-bg: none !default;
$pagination-hover-link-color: $link-color-hover !default;

// current
$pagination-link-current-padding-y: 3px !default;
$pagination-link-current-padding-x: 6px !default;
$pagination-link-current-bg: $button-bg !default;
$pagination-link-current-color: $button-color !default;

// ---
// tables
$table-bordered: false !default; // if table has no border around it, first column and last column of each row don't need padding left/right
$table-striped: false !default;
$table-cell-padding-y: 9px !default;
$table-cell-padding-x: 15px !default;
$table-bg: #fff !default;
$table-color: inherit !default;
$table-border-width: 1px !default;
$table-border-style: solid !default;
$table-border-color: $component-border-color !default;
$table-border-radius: 0 !default;
$table-box-shadow: none !default;
$table-header-bg: none !default;
$table-header-color: inherit !default;
$table-header-font-size: inherit !default;
$table-header-font-family: $font-family-bold !default;
$table-header-font-weight: normal !default;
$table-header-text-transform: uppercase !default;
$table-body-font-size: inherit !default;
$table-row-stripped-bg: #f8f8f8 !default;
$table-responsive-padding-x: 110px !default;

// ---
// product listing
$product-listing-spacing-y: 40px !default;
$product-listing-padding-y: 30px !default;
$product-listing-padding-x: 15px !default;

$product-listing-hover-effect: true !default;
$product-listing-has-add-to-cart: false !default;
$product-listing-has-quick-view: false !default;

$product-listing-padding-mobile-y: 24px !default;
$product-listing-padding-mobile-x: 12px !default;

$product-listing-inner-padding: 15px !default;

$product-listing-addcart-show-only-hover: true !default;
$product-listing-addcart-qty-enable: false !default;
$product-listing-addcart-bottom: -($button-height + 10px) !default;

$product-listing-per-row: 5 !default;
$product-listing-per-row-computed: $grid-columns / $product-listing-per-row !default;

$product-listing-review-spacing-y-top: 3px !default;
$product-listing-review-spacing-y-bottom: 0 !default;

// order
$product-listing-image-order: 0 !default;
$product-listing-model-order: 10 !default;
$product-listing-price-order: 20 !default;
$product-listing-review-order: 30 !default;
$product-listing-add-to-cart: 40 !default;

// ---
// product listing filter
$enable-filter-listing: true !default; // Default: false | if true, enable ENABLE_FILTER_LISTING in config/categories
$filter-listing-spacer-x: 6px !default;
$filter-listing-padding-y: 0 !default;
$filter-listing-padding-x: 8px !default;
$filter-listing-bg: transparent !default;
$filter-listing-bg-hover: transparent !default;
$filter-listing-color: $button-secondary-color !default;
$filter-listing-color-hover: $button-secondary-color-hover !default;
$filter-listing-border-width: 1px !default;

$filter-listing-border-style: solid !default;
$filter-listing-border-color: $button-border-color !default;
$filter-listing-border-color-hover: $button-border-color !default;
$filter-listing-border-radius: 3px !default;
$filter-listing-font-family: inherit !default;
$filter-listing-font-size: 13px !default;
$filter-listing-font-weight: inherit !default;
$filter-listing-text-decoration: none !default;
$filter-listing-line-height: 1 !default;

// ---
// product badge
$product-badge-padding-y: 20px !default;
$product-badge-padding-x: 20px !default;
$product-badge-sale-bg: url(/img/sale_badge.svg) no-repeat 0 0 !default;
$product-badge-new-bg: url(/img/new_badge.svg) no-repeat 0 0 !default;
$product-badge-border-width: 0 !default;
$product-badge-border-style: solid !default;
$product-badge-border-color: transparent !default;
$product-badge-border-radius: 0 !default;
$product-badge-color: $white !default;
$product-badge-font-family: $font-family-bold !default;
$product-badge-font-size: 0px !default;
$product-badge-font-weight: inherit !default;
$product-badge-line-height: 1 !default;
$product-badge-position-x: 0;
$product-badge-position-y: 0;

// ---
// Block UI
$blockui-bg: rgba(255, 255, 255, 0.8) !default;
$blockui-bg-image: url(/img/loading.svg) !default;

// ---
// loading
$loading-height: 50px !default;
$loading-width: 50px !default;

$loading-color: $button-bg !default;

// ---
// Tabs
// nav
$tab-nav-border-bottom-only-enable: false !default;

$tab-nav-spacer-x: 0 !default;
$tab-nav-padding-y: 21px !default;
$tab-nav-padding-x: 21px !default; // padding-y will be used as padding-x when on mobile devices
$tab-nav-margin-y-bottom: 27px !default;

$tab-nav-list-bg: transparent !default;
$tab-nav-list-display: flex !default;
$tab-nav-list-padding-y: 0 !default;
$tab-nav-list-padding-x: 0 !default;

$tab-nav-bg: #f2f2f2 !default;
$tab-nav-bg-hover: darken($tab-nav-bg, 1%) !default;
$tab-nav-bg-selected: $white !default;
$tab-nav-border-width: $component-border-width !default;
$tab-nav-border-style: $component-border-style !default;
$tab-nav-border-color: $component-border-color !default;
$tab-nav-border-color-select: $tab-nav-border-color !default;
$tab-nav-border-radius: 0 !default;
$tab-nav-box-shadow: none !default;
$tab-nav-box-shadow-hover: none !default;
$tab-nav-box-shadow-selected: none !default;
$tab-nav-color: inherit !default;
$tab-nav-color-hover: inherit !default;
$tab-nav-color-selected: $tab-nav-color-hover !default;
$tab-nav-font-family: $font-family-bold !default;
$tab-nav-font-size: inherit !default;
$tab-nav-font-weight: inherit !default;
$tab-nav-text-transform: uppercase !default;
$tab-nav-line-height: 1 !default;

// panel
$tab-panel-padding-y: 21px !default;
$tab-panel-padding-x: 21px !default;
$tab-panel-border-width: $component-border-width !default;
$tab-panel-border-style: $component-border-style !default;
$tab-panel-border-color: $component-border-color !default;
$tab-panel-border-radius: 0 !default;
$enable-tab-panel-custom-typography: false !default;
$tab-panel-color: inherit !default;
$tab-panel-font-family: inherit !default;
$tab-panel-font-size: inherit !default;
$tab-panel-font-weight: inherit !default;
$tab-panel-line-height: inherit !default;

// stars
$stars-color: $black !default;
$stars-color-off: $gray !default;
$stars-letter-spacing: 0 !default;

// ---
// save for later
$save-for-later-trigger-icon-height: 24px !default;
$save-for-later-trigger-icon-width: 26px !default;
$save-for-later-trigger-color: #ccc !default;
$save-for-later-trigger-color-hover: $black !default;
$save-for-later-font-size: 16px !default;
$save-for-later-image-container-height: 30px !default;
$save-for-later-image-container-width: 30px !default;

// ---
// sidebar
$sidebar-width: 226px !default;
$sidebar-width-spacing-x: $elements-spacing-lg !default;

$sidebar-wrapper-spacing-y: $elements-spacing-lg !default;
$sidebar-nav-item-spacing-y: 0.233rem !default;
$sidebar-nav-item-font-size: inherit !default;
$sidebar-nav-item-line-height: inherit !default;
$sidebar-nav-item-child-spacing-y: $sidebar-nav-item-spacing-y !default;
$sidebar-nav-item-child-font-size: $font-size-sm !default;
$sidebar-nav-item-child-line-height: $line-height-sm !default;

$sidbear-nav-link-background-size: 100% 0 !default;
$sidbear-nav-link-background-size-hover: 100% 100% !default;
$sidebar-nav-link-color: $black !default;
$sidebar-nav-link-color-hover: $link-color-hover !default;
$sidebar-nav-link-text-decoration: none !default;
$sidebar-nav-link-text-decoration-hover: none !default;

$sidebar-nav-link-current-color: $sidebar-nav-link-color-hover !default;
$sidebar-nav-link-current-font-weight: $font-weight-bold !default;

$sidebar-nav-sub-spacing: 9px 0 3px 15px !default;

$sidebar-description-spacing-y-top: $elements-spacing-lg !default;
$sidebar-description-title-font-weight: $font-weight-bold !default;
$sidebar-description-title-spacing-y: 9px !default;

// flyout
$flyout-padding-y: 15px !default;
$flyout-padding-x: 15px !default;
$flyout-width: 280px !default;

$flyout-bg: $black !default;

$flyout-link-padding-y: 5px !default;
$flyout-link-padding-x-left: 0 !default;
$flyout-link-padding-x-right: 10px !default;
$flyout-link-bg: none !default;
$flyout-link-color: $white !default;
$flyout-link-line-height: 1.35 !default;
$flyout-link-text-decoration: none !default;

$flyout-icon-height: 15px !default;
$flyout-icon-width: 8px !default;
$flyout-icon-color: currentColor !default;

$flyout-icon-position-y: 6px;

// ---
// shopping cart
$cart-table-padding-y: 15px !default;
$cart-table-padding-x-left: 95px !default;

// ---
// autocomplete
$autocomplete-bg: $component-bg !default;
$autocomplete-border-color: $component-border-color !default;
$autocomplete-border-style: $component-border-style !default;
$autocomplete-border-width: $component-border-width !default;
$autocomplete-box-shadow: $component-box-shadow !default;
$autocomplete-border-radius: $component-border-radius !default;
$autocomplete-max-height: 300px !default;
$autocomplete-z-index: 9999 !default;

$autocomplete-padding-y: 6px !default;
$autocomplete-padding-x: 10px !default;
$autocomplete-item-bg: transparent !default;
$autocomplete-item-bg-hover: $link-bg-hover !default;
$autocomplete-item-color: inherit !default;
$autocomplete-font-size: inherit !default;
$autocomplete-line-height: inherit !default;

$autocomplete-img-height: 50px !default;
$autocomplete-img-width: 50px !default;

$autocomplete-loading-enable: false !default;

$autocomplete-loading-bg-color: $autocomplete-bg !default;
$autocomplete-loading-bg-image-color: #eaeaea !default;

// ---
// carousel
$carousel-nav-prev-left: 0 !default;
$carousel-nav-next-right: 0 !default;

$carousel-dot-space-x: 6px !default;
$carousel-dot-bg: $white !default;
$carousel-dot-border-color: $button-bg !default;
$carousel-dot-border-style: $button-border-style !default;
$carousel-dot-border-width: 2px !default;
$carousel-dot-radius: 50% !default;
$carousel-dot-bg-active: $button-bg !default;
$carousel-dot-height: 12px !default;
$carousel-dot-width: 12px !default;
$carousel-dot-height-active: 15px !default;
$carousel-dot-width-active: 15px !default;

// ---
// popup
$popup-overlay-bg: $component-overlay-bg !default;

$popup-content-bg: #fff !default;
$popup-content-border-width: $component-border-width !default;
$popup-content-border-style: $component-border-style !default;
$popup-content-border-color: $component-border-color !default;
$popup-content-box-shadow: $component-box-shadow !default;
$popup-content-padding: 40px !default;
$popup-content-padding-sm: 20px !default;

$popup-title-font-size: $h2-font-size !default;
$popup-title-spacing: 0 0 24px 0 !default;

$popup-close-position-top: $elements-spacing !default;
$popup-close-position-right: $elements-spacing !default;
$popup-close-height: 18px !default;
$popup-close-width: 18px !default;

// ---
// tooltips
$tooltip-bg: $message-attention-bg !default;
$tooltip-border-color: $message-attention-border-color !default;
$tooltip-border-radius: $component-border-radius !default;
$tooltip-box-shadow: $component-box-shadow !default;
$tooltip-font-size: $font-size-sm !default;
$tooltip-line-height: $line-height-sm !default;

$tooltip-padding: 12px 15px !default;

$tooltip-height: 22px !default;
$tooltip-width: 22px !default;

// ---
// progress bar
$progress-bar-bg: $component-bg !default;
$progress-bar-border-width: $component-border-width !default;
$progress-bar-border-style: $component-border-style !default;
$progress-bar-border-color: $component-border-color !default;
$progress-bar-border-radius: $component-border-radius !default;
$progress-bar-height: 20px !default;

$progress-bar-load-bg: darken($component-bg, 10%) !default;
$progress-bar-load-height: 100% !default;
$progress-bar-load-border-radius: $progress-bar-border-radius !default;

// ---
// order history progress bar colors
$shipment-progress-bar-load-started-bg: $message-success-color !default;
$shipment-progress-bar-load-completed-bg: $message-success-color !default;
$shipment-progress-bar-load-cancelled-bg: $message-error-color !default;

// ---
// feedback
$feedback-padding-y: 25px !default;
$feedback-padding-x: 132px !default;

$feedback-title-font-size: $font-size-base;
$feedback-for-font-size: $font-size-sm;

// ---
// stock
$stock-color: #96cc5f !default;

// ---
// order summary
$order-summary-bg: #f2f5f9 !default;
$order-summary-border-bottom: $component-border-color !default;

// ---
// checkout
// checkout mini cart
$enable-checkout-cart-card: true !default;

$checkout-cart-padding-y: 10px !default;
$checkout-cart-padding-x: 10px !default;
$checkout-cart-stickty-position-top: $elements-spacing-lg;

$checkout-cart-bg: $component-bg !default;
$checkout-cart-border: $component-border-width !default;
$checkout-cart-border-color: $component-border-color !default;
$checkout-cart-border-style: $component-border-style !default;
$checkout-cart-box-shadow: $component-box-shadow !default;

// ---
// header
$h-header: 131px !default;

// search
$bg-search-wrapper: #000 !default;

// ---
// footer
$footer-bg: #000 !default;
$footer-color: #ffffff !default;
$footer-link-color: #ffffff !default;
$footer-link-color-hover: $gray !default;

// ---
// blog
$blog-max-width: 1200px;

$widget-spacing-y-top: $elements-spacing-lg !default;
$widget-padding-y-top: $elements-spacing-lg !default;

$widget-border-width: $component-border-width !default;
$widget-border-style: $component-border-style !default;
$widget-border-color: $component-border-color !default;

$widget-font-size: $font-size-sm !default;

$widget-title-font-size: $font-size-md !default;
$widget-title-font-family: inherit !default;
$widget-title-font-weight: inherit !default;
$widget-title-line-height: 1 !default;
$widget-title-text-transform: uppercase !default;

$widget-link-color: inherit !default;
$widget-link-color-hover: $link-color-hover !default;

$widget-link-text-decoration: none !default;
$widget-link-text-decoration-hover: none !default;

// entry header
$entry-header-spacing-y-bottom: 1.5rem !default;

// post
$post-spacing-y-top: $elements-spacing-lg !default;
$post-padding-y-top: $elements-spacing-lg !default;

$post-border-width: $component-border-width !default;
$post-border-style: $component-border-style !default;
$post-border-color: $component-border-color !default;

$post-title-font-size: 30px !default;
$post-title-line-height: $post-title-font-size + $line-height-base-sm !default;
$post-title-spacing-y-bottom: 0 !default;

$post-title-link-color: inherit !default;
$post-title-link-color-hover: $link-color-hover !default;

// entry sidebar
$entry-sidebar-font-size: $font-size-sm !default;
