// Making changes to this file
// 
// 1. Enable the overwrite variable related to this component in _variables.scss under "component overwrites"
// 2. Changes here will only affect the component in all pages its been added

@mixin custom-radio-checkbox {
    .custom-checkbox,
    .custom-radio {
        label {
            &::after {
                background-position: left 2px top 1.5px;
            }
        }
    }
}