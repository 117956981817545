.row {
    @include make-row;
    flex-direction: column;

    & + .row {
        margin-top: $grid-gutter-width;
    }

    [class^='col-'] {
        @include make-col-ready;

        input,
        select,
        textarea {
            max-width: none;
        }
    }

    // responsive
    @media (min-width: $bp-site-mp) {
        flex-direction: row;

        // forms
        > .form-group {
            margin-top: 0;
        }
    }
}

.col-12 {
    @include make-col(12);
}

.col-6 {
    @include make-col(12);

    & + .col-6 {
        margin-top: rem(21);
    }

    @media (min-width: $bp-site-mp) {
        @include make-col(6);

        & + .col-6 {
            margin-top: 0;
        }
    }
}
