//
.breadcrumb {
    font-size: rem($breadcrumb-font-size);
    position: relative;
    margin-bottom: $breadcrumb-spacing-y;

    // ---
    // responsive
    @media (max-width: $bp-site-ml) {
        margin-bottom: 1rem;
    }

    //
    ol {
        border-bottom: $breadcrumb-border-width $breadcrumb-border-style $breadcrumb-border-color;
        display: flex;
        align-items: baseline;
        flex-wrap: wrap;
        list-style: none;
        margin: 0;
        padding: $breadcrumb-list-padding-y $breadcrumb-list-padding-x;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        // ---
        // responsive
        @media (max-width: 1440px) {
            border-top: $breadcrumb-border-width $breadcrumb-border-style $breadcrumb-border-color;
        }
    }

    li {
        color: $breadcrumb-color;
        display: inline-block;
        font-family: $breadcrumb-font-weight;
        margin: 0;
        position: relative;
        text-transform: $breadcrumb-text-transform;
        margin-right: $breadcrumb-list-item-spacing-x;

        // separator
        &:not(:last-child):after {
            content: $breadcrumb-separator;
            margin-left: $breadcrumb-list-item-spacing-x;
        }

        // first element
        &:first-of-type {
            padding-left: 0;

            &:first-of-type:before {
                display: none;
            }
        }

        // last
        &:last-child {
            color: $breadcrumb-color-active;
            font-size: rem($breadcrumb-font-size-active);

            @if ($breadcrumb-font-size-active != "inherit") {
                line-height: calc(#{rem($breadcrumb-font-size-active)} + #{$breadcrumb-line-height-active});
            }

            a {
                pointer-events: none;
            }
        }
    }

    // links
    a {
        color: $breadcrumb-link-color;
        text-decoration: $breadcrumb-link-text-decoration;
        
        &:hover {
            color: $breadcrumb-link-color-hover;
            text-decoration: $breadcrumb-link-text-decoration-hover;
        }
    }

    @media (max-width: $bp-site-ml) {
        li {
            &:not(:first-child):not(:last-child) {
                display: none;
            }
        }
    }
}

// ---
// theme overwrites
@if (variable-exists(breadcrumb-overwrite)) {
    @if ($breadcrumb-overwrite) {
        @include breadcrumb;
    }
}
