$mb-message: 24px;

.message {
	border: $message-border-width $message-border-style $message-border-color;
	border-radius: $message-border-radius;
	clear: both;
	margin: 0 0 $mb-message;
	position: relative;
	max-width: none;
	padding: $message-padding-y $message-padding-x;

	&--success {
		background-color: $message-success-bg;
   		border: $message-success-border-color;
		color: $message-success-color;
	}

	&--error {
		background-color: $message-error-bg;
   		border: $message-error-border-color;
		color: $message-error-color;
	}

	&--attention {
		background-color: $message-attention-bg;
   		border: $message-attention-border-color;
		color: $message-attention-color;
	}

	&--text {
		background: none;
		border: none;
		padding: 0;
	}

	* {
		margin: 0;
	}

	// ---
	&:not(:empty) + & {
		margin-top: -$mb-message;
	}
}

.message:empty {
	display: none;
}