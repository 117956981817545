.footer {
    background-color: #323332;
    color: #ffffff;
    font-size: 14px;
    margin-top: 90px;

    a {
        color: inherit;
    }

    a:hover {
        color: $link-color-hover;
        text-decoration: none;
    }
}

// nav/subscribe wrapper
.footer-nav-subscribe {
    // background: url(/img/logo_eon_smoke_footer.png) no-repeat right 0 top 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: 37px;
    min-height: 209px;

    @media (min-width: 1024px) {
        background-position: right 30px top 0;
    }
}

// navs
.footer-nav {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 100%;

    div  + div {
        margin-top: 30px;
    }
    
    h2 {
        display: block;
        color: #6ab131;
        font-size: 16px;
        font-weight: 600;
        line-height: 1;
        margin-bottom: 21px;
        margin-top: 0;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li:not(:first-child) {
            margin-top: 12px;
        }
    }

    // responsive
    @media (min-width: 768px) {
        flex-direction: row;
        width: 570px;

        div + div {
            margin-top: 0;
        }
    }

    @media (min-width: 1366px) {
        width: calc(46.9941%  - 70px);
        margin-right: 0;
    }
}

// newsletter
.footer-newsletter {
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.08);
    background-color: #595a5a;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 45px 0;
    padding: 51px 21px 57px;
    text-align: center;
    width: 100%;
    
    form {
        width: 100%;
        max-width: 358px;
        text-align: center;
    }

    h2 {
        margin: 0 0 18px;
        font-weight: 600;
        font-size: 30px;
    }

    p {
        margin-top: 0;
        margin-bottom: 27px;
    }

    .form-group__inline {
        position: relative;
    }

    input {
        width: 100%;
        max-width: none;
    }

    .button {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        height: auto;
        width: 50px;
        padding: 0;
    }

    // responsive
    @media (min-width: 1366px) {
        position: relative;
        top: calc(-37px - 30px);
        margin: 0 10.2526% 0 auto;
        width: 39.2961%;
    }
}


// footer down
.footer-site-info  {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 45px; // if there is the newsletter, maybe this is not necessary

    @media (min-width: 768px) {
        flex-direction: row;
        justify-content: center; // if social media is added, we can remove it
    }
}

// security
.footer-security {
    display: flex;
    align-items: center;

    img {
        height: 30px;
    }

    * + * {
        margin-left: $elements-spacing;
    }

    @media (min-width: 414px) {
        img {
            height: auto;
        }

        * + * {
            margin-left: $elements-spacing;
        }
    }
}

// social
.footer-social {
    display: flex;
    margin-top: 30px;

    span {
        margin-right: 15px;
    }

    ul {
        display: flex;
        align-items: center;
        list-style: none;
        padding-left: 0;
        margin: 0;
    }

    li + li {
        margin-left: 20px;
    }

    @media (min-width: 768px) {
        margin-left: auto;
        margin-top: 0;
    }
}

// copyright
.footer-copyright {
    border-top: 1px solid #595a5a;
    margin-top: 30px;
    margin-bottom: 0;
    padding-top: 27px;
    padding-bottom: 27px;
    text-align: center;
    width: 100%;
}