// ---
// string replace
@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      str-replace(
        str-slice($string, $index + str-length($search)),
        $search,
        $replace
      );
  }

  @return $string;
}

// ---
// placeholder
@mixin placeholder {
  &.placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
}

// ---
// hide
@mixin none {
  display: none;
}

// ---
// clear list
@mixin sr-only {
  position: absolute;
  top: -9999px;
  visibility: hidden;
}

// ---
// box
@mixin box {
  background: $component-bg;
  border-radius: 0;
  box-shadow: $component-box-shadow;
  border: $component-border-width $component-border-style $component-border-color;
  padding: $grid-gutter-width / 2 * 2;

  & + * {
    margin-top: 21px;
  }

  @media (min-width: $bp-site-ml) {
    padding: ($grid-gutter-width / 2 * 3);

    & + * {
      margin-top: 30px;
    }
  }
}

// ---
// custom list for account pages (address book, payment info and recurring order)
@mixin account-items($type) {
  // ---
  // item
  @if $type == box {
    li {
      @include box;
    }
  } @else {
    li {
      margin-bottom: $elements-spacing-lg;
      padding-bottom: $elements-spacing-lg;
      border-bottom: $component-border-width $component-border-style $component-border-color;
    }

    li:last-child {
      margin-bottom: 0;
    }
  }

  // ---
  // buttons
  &__actions {
    margin-top: 15px;
  }

  @media (min-width: $bp-site-mp) {
    // ---
    // item
    &__item {
      display: flex;
    }

    // ---
    // details
    &__details {
      width: 63.5%;
    }

    // ---
    // buttons
    &__actions {
      margin-top: 0;
      margin-left: auto;
      // width: 190px;
    }
  }
}

// ---
// products with attributes in table (cart, checkout and order details)
@mixin prod-list-attr-table {
  font-size: rem(16);
  list-style: none;
  padding-left: 0;

  li + li {
    margin-top: 0;
  }
}

// ---
// clear list
@mixin clear-ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

// ---
// table responsive
@mixin table-responsive {
  thead {
    display: none;
  }

  tbody,
  tbody tr,
  tbody td {
    display: block;
    border: none;
  }

  tr {
    padding-top: $table-cell-padding-y;
    padding-bottom: $table-cell-padding-y;
  }

  tbody tr {
    border-top: $component-border-width $component-border-style $component-border-color;

    td:first-child {
      border-top: none;
      padding-top: 0;
    }

    td:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
  }

  tbody td {
    display: block;
    padding-top: 5px;
    padding-bottom: 5px;
    position: relative;
    width: 100%;
  }

  tbody td[data-th] {
    padding-left: $table-responsive-padding-x;

    &:before {
      content: attr(data-th);
      text-align: left;
      position: absolute;

      @if($table-bordered) {
        left: $table-cell-padding-x;
      } @else {
        left: 0;
      }
    }
  }

  // ---
  // foot
  tfoot {
    display: table;
    width: 100%;

    tr {
      border: none;
    }

    td {
      padding: $table-cell-padding-y $table-cell-padding-x;
      @if ($table-bordered == false) {
        padding-left: 0;
        padding-right: 0;
      }
    }

    tr td:first-child {
      padding-right: 10px;
      width: 90%;
    }
  }
}

@mixin clear-inline-style {
  color: inherit !important;
  font-family: inherit !important;
  font-size: inherit !important;
  font-weight: inherit;
  text-align: left !important;
}

// Breakpoint viewport sizes and media queries.
//
// Breakpoints are defined as a map of (name: minimum width), order from small to large:
//
//    (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px)
//
// The map defined in the `$grid-breakpoints` global variable is used as the `$breakpoints` argument by default.

// Name of the next breakpoint, or null for the last breakpoint.
//
//    >> breakpoint-next(sm)
//    md
//    >> breakpoint-next(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    md
//    >> breakpoint-next(sm, $breakpoint-names: (xs sm md lg xl))
//    md
@function breakpoint-next(
  $name,
  $breakpoints: $grid-breakpoints,
  $breakpoint-names: map-keys($breakpoints)
) {
  $n: index($breakpoint-names, $name);
  @return if(
    $n < length($breakpoint-names),
    nth($breakpoint-names, $n + 1),
    null
  );
}

// Minimum breakpoint width. Null for the smallest (first) breakpoint.
//
//    >> breakpoint-min(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    576px
@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min != 0, $min, null);
}

// Maximum breakpoint width. Null for the largest (last) breakpoint.
// The maximum value is calculated as the minimum of the next one less 0.02px
// to work around the limitations of `min-` and `max-` prefixes and viewports with fractional widths.
// See https://www.w3.org/TR/mediaqueries-4/#mq-min-max
// Uses 0.02px rather than 0.01px to work around a current rounding bug in Safari.
// See https://bugs.webkit.org/show_bug.cgi?id=178261
//
//    >> breakpoint-max(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    767.98px
@function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
  $next: breakpoint-next($name, $breakpoints);
  @return if($next, breakpoint-min($next, $breakpoints) - 0.02px, null);
}

// Returns a blank string if smallest breakpoint, otherwise returns the name with a dash infront.
// Useful for making responsive utilities.
//
//    >> breakpoint-infix(xs, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    ""  (Returns a blank string)
//    >> breakpoint-infix(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    "-sm"
@function breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
  @return if(breakpoint-min($name, $breakpoints) == null, "", "-#{$name}");
}

// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

// Media of at most the maximum breakpoint width. No query for the largest breakpoint.
// Makes the @content apply to the given breakpoint and narrower.
@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
  $max: breakpoint-max($name, $breakpoints);
  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

// Media that spans multiple breakpoint widths.
// Makes the @content apply between the min and max breakpoints
@mixin media-breakpoint-between(
  $lower,
  $upper,
  $breakpoints: $grid-breakpoints
) {
  $min: breakpoint-min($lower, $breakpoints);
  $max: breakpoint-max($upper, $breakpoints);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include media-breakpoint-up($lower, $breakpoints) {
      @content;
    }
  } @else if $min == null {
    @include media-breakpoint-down($upper, $breakpoints) {
      @content;
    }
  }
}

// Media between the breakpoint's minimum and maximum widths.
// No minimum for the smallest breakpoint, and no maximum for the largest one.
// Makes the @content apply only to the given breakpoint, not viewports any wider or narrower.
@mixin media-breakpoint-only($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  $max: breakpoint-max($name, $breakpoints);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include media-breakpoint-up($name, $breakpoints) {
      @content;
    }
  } @else if $min == null {
    @include media-breakpoint-down($name, $breakpoints) {
      @content;
    }
  }
}

/// Grid system
//
// Generate semantic grid columns with these mixins.

@mixin make-container() {
  width: 100%;
  padding-right: ($grid-gutter-width / 2);
  padding-left: ($grid-gutter-width / 2);
  margin-right: auto;
  margin-left: auto;
}

// For each breakpoint, define the maximum width of the container in a media query
@mixin make-container-max-widths(
  $max-widths: $container-max-widths,
  $breakpoints: $grid-breakpoints
) {
  @each $breakpoint, $container-max-width in $max-widths {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      max-width: $container-max-width;
    }
  }
}

@mixin make-row() {
  display: flex;
  flex-wrap: wrap;
  margin-right: ($grid-gutter-width / -2);
  margin-left: ($grid-gutter-width / -2);
}

@mixin make-col-ready() {
  position: relative;
  // Prevent columns from becoming too narrow when at smaller grid tiers by
  // always setting `width: 100%;`. This works because we use `flex` values
  // later on to override this initial width.
  width: 100%;
  min-height: 1px; // Prevent collapsing
  padding-right: ($grid-gutter-width / 2);
  padding-left: ($grid-gutter-width / 2);
}

@mixin make-col($size, $columns: $grid-columns) {
  flex: 0 0 percentage($size / $columns);
  // Add a `max-width` to ensure content within each column does not blow out
  // the width of the column. Applies to IE10+ and Firefox. Chrome and Safari
  // do not appear to require this.
  max-width: percentage($size / $columns);
}

@mixin make-col-offset($size, $columns: $grid-columns) {
  $num: $size / $columns;
  margin-left: if($num == 0, 0, percentage($num));
}


// Lists

// Unstyled keeps list items block level, just removes default browser padding and list-style
@mixin list-unstyled {
	padding-left: 0;
	list-style: none;
}


// Only display content to screen readers
//
// See: https://a11yproject.com/posts/how-to-hide-content/
// See: https://hugogiraudel.com/2016/10/13/css-hide-and-seek/

@mixin sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}


// Use in conjunction with .sr-only to only display content when it's focused.
//
// Useful for "Skip to main content" links; see https://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1
//
// Credit: HTML5 Boilerplate

@mixin sr-only-focusable {
  &:active,
  &:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }
}



// 
// 
// 
@mixin overlay {
  background-color: $component-overlay-bg;
	display: block;
	position: fixed;
	  top: 0;
	  right: 0;
	  bottom: 0;
	left: 0;
	z-index: 9998;
}


@mixin button-stack {
  flex-direction: column;
  align-items: stretch;

  > *,
  [class^="button"] {
    margin-left: 0;
    width: 100%;
  }

  > * + * {
    margin-top: $elements-spacing;
  }
}