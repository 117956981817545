.newsletter {

    // inputs
    input[type="email"] {
        flex: 1;
    }

    // status
    &.disabled {
        pointer-events: none;
        opacity: .85;
    }
}