.wrapper {
	margin-left: auto;
	margin-right: auto;
	position: relative;
	width: calc(100% - 30px);
	max-width: map-get($container-max-widths, xxl);

	.wrapper {
		margin-left: 0;
		margin-right: 0;
		width: auto;
	}

	@include media-breakpoint-up(sm) {
		width: calc(100% - 60px);
	}

	@include media-breakpoint-up(lg) {
		width: calc(100% - 120px);
	}

	// small
	&--xsmall,
	&--small,
	&--medium,
	&--lg,
	&--xl {
		margin-left: auto;
		margin-right: auto;
	}

	&--xsmall {
		max-width: 426px;
	}

	&--small {
		max-width: 768px;
	}

	&--medium {
		max-width: 1024px;
	}

	&--lg {
		max-width: 1232px;
	}

	// &--xl {
	// 	max-width: map-get($container-max-widths, xl);
	// }
}

//
main {
	flex: 1 0 auto;

	> .wrapper {
		height: 100%;
	}
}
