// ---
// component
.popup {
    background: $popup-overlay-bg;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 10;

    &[aria-hidden="true"] {
        display: none;
    }

    // ---
    // close
    &__close {
        background: none;
        position: absolute;
        top: $popup-close-position-top;
        right: $popup-close-position-right;
        height: $popup-close-height;
        width: $popup-close-width;

        &:hover {
            background: none;
        }

        svg {
            display: block;
        }
    }

    // content
    &__content {
        border: $popup-content-border-width $popup-content-border-style $popup-content-border-color;
        box-shadow: $popup-content-box-shadow;
        background: $popup-content-bg;
        padding: $popup-content-padding;
        width: 90%;
        max-width: 576px;
        max-height: 95%;
        overflow-y: auto;
        position: relative;
    }

    // title
    &__title {
        display: block;
        font-size: $popup-title-font-size;
        margin: $popup-title-spacing;
    }

    // responsive
    @media (max-width: $bp-site-ml) {
        &__content {
            padding-left: $popup-content-padding-sm;
            padding-right: $popup-content-padding-sm;
        }
    }
}

// ---
// theme overwrites
@if (variable-exists(popup-overwrite)) {
    @if ($popup-overwrite) {
        @include popup;
    }
}
